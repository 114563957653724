import React, { Component } from "react";
import "./LandingPage.css";
import withRouter from "./../../components/withRouter";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { withMsal } from '@azure/msal-react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import { startCase } from 'lodash'
import NavBar from "../../components/NavBar/NavBar";
import config from "../../config/config";
import { compose } from "recompose";
import {
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControlLabel,
  Icon,
  Radio,
} from "@material-ui/core";
import PgBuddyImage from "./../../assets/pgBuddy.png";
import conferenceImage from "./../../assets/conference.png";
import strategicEngagement from "./../../assets/strategicEngagement.png";
import CloseIcon from "@material-ui/icons/Close";
import axios from 'axios';
import WarningIcon from '@material-ui/icons/Warning';
import { Button } from "@mui/material";
const moment = require('moment');

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      selectedPGBuddyProgram: "AzureML",
      allRequest: [],
      activeRequest: [],
      viewAll: false,
      isSignedIn: false,
      fetchedRequestStatus: '',
      isEbcWarningPopupOpen: false,
      showFindSpeaker: false

    };
    this.handleOpenPopup = this.handleOpenPopup.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.handleProgramChange = this.handleProgramChange.bind(this);
    this.handleSubmit = this.handlePopupSubmit.bind(this);
    this.handleNaviagation = this.handleNaviagation.bind(this);
  }
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    var successfulSignIn = false;
    // Function to acquire token silently
    try {
      const { instance, accounts } = this.props.msalContext;
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: ["User.Read", "openid", "profile", "offline_access", "email", "User.ReadBasic.All"],
          account: accounts ? accounts[0] : null,
        };
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        sessionStorage.setItem("userName", response.account.name);
        sessionStorage.setItem("Emailid", response.account.username);
        sessionStorage.setItem("accountId", response.account.localAccountId);
        sessionStorage.setItem("userToken", response.accessToken);
        this.setState({
          isSignedIn: true
        })
        successfulSignIn = true
      } else {
        throw Error("not found any current active account")
      }
    } catch (error) {
      console.log('Error acquiring token silently:', error);
      sessionStorage.clear();
      window.location.replace('/');
    }

    if (successfulSignIn) {
      this.AuthorizeFindSpeaker()
      try {
        const url = `${config.apiBaseUrl}/request/` + sessionStorage.getItem("accountId");
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        this.setState({
          allRequest: response.data?.data?.active?.count > 0 ? response.data.data.active.value : [],
          activeRequest: response.data?.data?.active?.count > 0 ? response.data.data.active.value.slice(0, 4) : [],
          fetchedRequestStatus: response.data?.data?.active?.count > 0 ? '' : 'No requests created under this account yet.'
        })
      } catch (error) {
        console.error('Failed to fetch Request Data', error);
      }
    }
  }

  closeEbcWarningPopup = () => {
    this.setState({
      isEbcWarningPopupOpen: false
    })
  }

  handleOpenPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  handleClosePopup = () => {
    this.setState({ isPopupOpen: false });
  };


  handleProgramChange = (event) => {
    this.setState({ selectedPGBuddyProgram: event.target.value });
  };

  openNavigationLinks = (naviagtionLink) => {
    window.open(naviagtionLink, '_blank');
  }

  handleNaviagation = (path, props) => {
    const { navigate } = this.props;
    navigate(path, { state: { ...props }, });
  };

  handdleViewAll = () => {
    if (this.state.viewAll === false) {
      this.setState({
        activeRequest: this.state.allRequest,
        viewAll: !this.state.viewAll
      })
    } else {
      this.setState({
        activeRequest: this.state.allRequest?.length > 0 ? this.state.allRequest.slice(0, 4) : [],
        viewAll: !this.state.viewAll
      })
    }
  }

  handlePopupSubmit = () => {
    // Store the selected program state and perform any desired actions
    this.handleClosePopup();
    this.handleNaviagation("/Pgbuddy", { program: this.state.selectedPGBuddyProgram });
  };

  AuthorizeFindSpeaker = () => {
    let todayDate = moment().format('MM-DD-YYYY');
    const apiConfig = {
      headers: {
        'content-type': 'application/json',
      }
    };
    axios.get(`${config.apiBaseUrl}speakerProfiles/permissions/` + sessionStorage.getItem('Emailid') + '/' + todayDate, apiConfig)
      .then(response => {
        if (response.data.status) {
          let responseData = response.data.data.fields
          let permissions = (responseData['Custom.TimeBoundPermission']).split(';')

          const startDate = new Date(responseData['Custom.TimeBoundPermissionStartDate']);
          let endDate = new Date(responseData['Custom.TimeBoundPermissionEndDate']);

          const today = new Date();

          
            endDate.setHours(0, 0, 0, 0);
            startDate.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

          if (responseData['Custom.TimeBoundPermissionEndDate']) {
            if (permissions.includes('Find Speaker') && today >= startDate && today <= endDate) {
              this.setState({
                showFindSpeaker: true
              })
            }
          }
          else {
            if (permissions.includes('Find Speaker') && today >= startDate) {
              this.setState({
                showFindSpeaker: true
              })
            }
          }
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  handdleView = (data) => {
    if (data.fields["Custom.EngagementStyle"] === "AI Platform Buddy Program") {
      const { navigate } = this.props;
      if (data.fields["Custom.RequestStatus"] === "Speaker Requested" || data.fields["Custom.RequestStatus"] === "Meeting Cancelled" || data.fields["Custom.RequestStatus"] === "Meeting Rescheduled") {
        navigate('/Pgbuddy', { state: { action: "edit", data: data } });
      } else {
        navigate('/Pgbuddy', { state: { action: "view", data: data } });
      }
    } else if (data.fields["Custom.EngagementStyle"] === "Strategic Customer Engagement or Event") {
      const { navigate } = this.props;
      if (data.fields["Custom.RequestStatus"] === "Speaker Requested" || data.fields["Custom.RequestStatus"] === "Meeting Cancelled" || data.fields["Custom.RequestStatus"] === "Meeting Rescheduled") {
        navigate('/StrategicEngagement', { state: { action: "edit", data: data } });
      } else {
        navigate('/StrategicEngagement', { state: { action: "view", data: data } });
      }
    } else if (data.fields["System.WorkItemType"] === "Technical Content Request") {
      const { navigate } = this.props;
      if (data.fields["Custom.TechnicalContentStatus"] === "Content Requested") {
        navigate('/TechnicalContent', { state: { action: "edit", data: data } });
      } else {
        navigate('/TechnicalContent', { state: { action: "view", data: data } });
      }
    } else if (data.fields["Custom.EngagementStyle"] === "Executive Briefing (EBC)") {
      this.setState({
        isEbcWarningPopupOpen: true
      })
    }
  }

  render() {
    const { isPopupOpen, selectedPGBuddyProgram } = this.state;
    return (
      <>
        {this.state.isSignedIn &&
          <div className="bodyContainer">
            <NavBar />
            <div className="new-application-note"><span>Welcome to the new Engage AI Request application! This is a Beta release. If you have any questions please reach out to <a href="mailto:engageai@microsoft.com" title="mailto:engageai@microsoft.com">engageai@microsoft.com</a></span></div>
            <div className="pageContainer">
              <Grid container spacing={2} className="gridContainer">
                <Grid
                  className="leftSectionContainer"
                  item
                  xs={5}
                  style={{ height: "100%" }}
                >
                  <div className="leftSection" style={{ height: "100%" }}>
                    <div className="title" style={{ marginBottom: '10px' }}>MY REQUESTS</div>
                    <Divider style={{ marginBottom: '10px', height: '2.5px' }}></Divider>
                    <div className="subtitle">
                      Here are the requests you have shared with us. Please click on
                      the desired Request ID to view/edit the request.
                    </div>
                    {this.state.activeRequest?.length > 0 && <><div className="my-request-containear">
                      <TableContainer className="table-contaier">
                        <Table className="table">
                          <TableHead className="tableHeading">
                            <TableRow className="tableRow">
                              <TableCell className="tableCell" align="left">
                                <div className="headerCellContent" style={{ color: 'white' }}>Request ID</div>
                              </TableCell>
                              <TableCell className="tableCell" align="left">
                                <div className="headerCellContent" style={{ color: 'white' }}>Title</div>
                              </TableCell>
                              <TableCell className="tableCell" align="left">
                                <div className="headerCellContent" style={{ color: 'white' }}> Style </div>
                              </TableCell>
                              <TableCell className="tableCell" align="left">
                                <div className="headerCellContent" style={{ color: 'white' }}>Status </div>
                              </TableCell>
                              <TableCell className="tableCell" align="left">
                                <div className="headerCellContent" style={{ color: 'white' }}> Created On </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.activeRequest.map((row, index) => (
                              <TableRow className="tableRow hoverRow" key={index}>
                                <TableCell className="tableCell td tableid" onClick={() => { this.handdleView(row) }}>
                                  {row.fields["Custom.RequestID"] ? row.fields["Custom.RequestID"] : ''}
                                </TableCell>
                                <TableCell className="tableCell td">
                                  {row.fields["Custom.EngagementStyle"] === "AI Platform Buddy Program" ? "-" : (row.fields["System.Title"] ? row.fields["System.Title"]?.length > 37 ? row.fields["System.Title"]?.slice(0, 37) + '...' : row.fields["System.Title"] : "")}
                                </TableCell>
                                <TableCell className="tableCell td">
                                  {row.fields["Custom.EngagementStyle"] ? startCase(row.fields["Custom.EngagementStyle"]) : row.fields["System.WorkItemType"] || ''}
                                </TableCell>
                                <TableCell className="tableCell td">
                                  {row.fields["Custom.RequestStatus"] || row.fields["Custom.TechnicalContentStatus"]}
                                </TableCell>
                                <TableCell className="tableCell td">
                                  {row.fields["Custom.CreatedOn"] ? moment.utc(row.fields["Custom.CreatedOn"]).format('MM/DD/YY') : ''}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                      <div className="view-all-link" onClick={this.handdleViewAll}>
                        <Link href="#" variant="body2">
                          {this.state.viewAll === true ? "View Less" : "View More"}
                        </Link>
                      </div></>}
                    {this.state.activeRequest?.length === 0 && <div> <TableContainer className="table-contaier">
                      <Table className="table">
                        <TableHead className="tableHeading">
                          <TableRow className="tableRow">
                            <TableCell className="tableCell" align="left">
                              <div className="headerCellContent" style={{ color: 'white' }}>Request ID</div>
                            </TableCell>
                            <TableCell className="tableCell" align="left">
                              <div className="headerCellContent" style={{ color: 'white' }}>Title</div>
                            </TableCell>
                            <TableCell className="tableCell" align="left">
                              <div className="headerCellContent" style={{ color: 'white' }}> Style </div>
                            </TableCell>
                            <TableCell className="tableCell" align="left">
                              <div className="headerCellContent" style={{ color: 'white' }}>Status </div>
                            </TableCell>
                            <TableCell className="tableCell" align="left">
                              <div className="headerCellContent" style={{ color: 'white' }}> Created On </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow className="tableRow">
                            <TableCell className="tableCell td" colSpan={5}>
                              {this.state.fetchedRequestStatus}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer></div>}
                  </div>

                  {this.state.showFindSpeaker &&
                    <div className="leftSection" style={{ height: "100%" }}>
                      <div className="title" style={{ marginBottom: '10px' }}>Speaker Lookup</div>
                      <Divider style={{ marginBottom: '10px', height: '2.5px' }}></Divider>
                      <div className="subtitle" style={{ marginBottom: '10px' }}>
                        Please click on the button below to find speakers near a given location.
                      </div>
                      <div>
                        <Button variant="contained" style={{ fontFamily: 'Segoe UI Regular', width: 'auto', backgroundColor: '#2088ff', height: '40px', fontSize: '12px', marginRight: '20px' }}
                          onClick={() => { this.handleNaviagation('/FindSpeaker') }}
                        >
                          Find Speaker
                        </Button>
                      </div>
                    </div>
                  }

                  <div className="accessLinks">
                    <Icon component={WarningIcon} className="warning-icon" /><div className="tableid" onClick={() => { this.openNavigationLinks("https://aka.ms/AOAI") }}>Note: We are currently receiving a very high volume of Azure OpenAl requests. Please use the assets available on GearUp while we work through the demand.</div></div>
                  <div className="accessLinks"><Icon component={WarningIcon} className="warning-icon" /><div className="tableid" onClick={() => { this.openNavigationLinks("https://aka.ms/oai/access") }}>Note: You and/or your customer need to apply for access to Azure OpenAl Service to ensure responsible use. Click here to apply.</div></div>
                </Grid>
                <Grid
                  container
                  item
                  xs={7}
                  className="rightSectionContainer"
                  style={{ height: "100%" }}
                >
                  <div className="rightSection">
                    <h1 className="content-title">CREATE A NEW REQUEST</h1>
                    <h2 className="content-subtitle">
                      Please select an engagement style.
                    </h2>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Card className="card" onClick={() => { this.handleNaviagation('/StrategicEngagement') }}>
                          <CardMedia
                            className="card-image"
                            component="img"
                            alt="Strategic Engagement"
                            src={strategicEngagement}
                          />
                          <CardContent className="card-content" style={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '16px', paddingLeft: '16px' }}>
                            <h3 className="card-title">Strategic Customer Engagement or Event</h3>
                            <p className="card-subtitle">
                              Request an AI expert speaker to present a session for a customer engagement or an internal or external event to discuss Azure AI products and services, address customer needs or demo products.
                            </p>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Card className="card" onClick={() => { this.handleNaviagation('/TechnicalContent') }}>
                          <CardMedia
                            className="card-image"
                            component="img"
                            alt="technicalContent"
                            src={conferenceImage}
                          />
                          <CardContent className="card-content" style={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '16px', paddingLeft: '16px' }}>
                            <h3 className="card-title">Technical Content Request</h3>
                            <p className="card-subtitle">
                              Technical content distributed through various media channels, platforms, or formats with the intention of educating and informing audiences by providing specific Azure AI product information.
                            </p>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Card className="card" onClick={this.handleOpenPopup}>
                          <CardMedia
                            className="card-image"
                            component="img"
                            alt="PG Buddy Program"
                            src={PgBuddyImage}
                          />
                          <CardContent className="card-content" style={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '16px', paddingLeft: '16px' }}>
                            <h3 className="card-title">AI Platform Buddy Program</h3>
                            <p className="card-subtitle">
                              Request AI Platform account support for a qualified customer opportunity.
                            </p>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>

            <Dialog
              open={isPopupOpen}
              onClose={this.handleClosePopup}
              maxWidth="md"
              fullWidth
              className="popupDialog"
            >
              <DialogTitle disableTypography className="popup-title">
                <h4>AI Platform Buddy Program</h4>
                <IconButton
                  aria-label="close"
                  onClick={this.handleClosePopup}
                  className="popup-close-icon"
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Divider className="popupHeadingDevider" />
              <DialogContent>
                <p className="popup-subtitle">
                  The PG Buddy Programme is currently only available for AzureML.
                  Select the programme you want your customer considered for below.
                </p>
                <FormControlLabel
                  value="AzureML"
                  control={<Radio name="program" color="primary" />}
                  label="AzureML"
                  checked={selectedPGBuddyProgram === "AzureML"}
                  onChange={this.handleProgramChange}
                />
              </DialogContent>
              <DialogActions>
                <button className="popupSubmit" onClick={this.handleSubmit}>
                  Select
                </button>
              </DialogActions>
            </Dialog>
            {this.state.isEbcWarningPopupOpen && (
              <div className="ebc-popup-overlay">
                <div className="ebc-popup">
                  <div className="ebc-popup-content">
                    <p className="custom-line-height">
                      This application no longer supports EBC requests. If you need help regarding this request, please reach out to <a href="mailto:engageai@microsoft.com" title="mailto:engageai@microsoft.com">engageai@microsoft.com</a>.
                    </p>
                    <br />
                    <button className="popupSubmit" onClick={this.closeEbcWarningPopup}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>}
      </>
    );
  }
}

export default compose(
  withRouter,
  withMsal
)(LandingPage);
