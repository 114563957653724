import React, { useState } from 'react';
import axios from 'axios';
import "./tablecomponant.css"
import { tableFormat } from './../../../config/tableFormat';
import baseConfig from './../../../config/config';

const reactSelectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        fontFamily: 'Segoe UI',
        width: '200px',
        border: '1px solid #888888',
        borderRadius: '5px',
        boxShadow: state.isFocused ? "0px 0px 0px black" : "none",
        "&:hover": {
            border: "1px solid #888888"
        }
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        fontFamily: 'Segoe UI',
        width: '200px',
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        top: '8px',
        left: '280px',
    }),
};

function truncateText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
    }
    return text;
}

const TableComponent = (props) => {
    const [selectedRows, setSelectedRows] = useState(props.selectedIndex || []);

    const handleRowSelect = (rowIndex) => {
        setSelectedRows((prevSelectedRows) => {
            let updatedSelectedRows;
            let allData = props.tableData.map(item => item.Email);
            if (prevSelectedRows.includes(rowIndex)) {
                updatedSelectedRows = prevSelectedRows.filter((row) => row !== rowIndex);
            } else {
                updatedSelectedRows = [...prevSelectedRows, rowIndex];
            }
            if (allData.length === updatedSelectedRows.length && allData.every(element => updatedSelectedRows.includes(element))) {
                props.setSelectAllChange(true)
            }
            else {
                props.setSelectAllChange(false)
            }
            props.handleSelectedIndex(updatedSelectedRows);
            return updatedSelectedRows;
        });
    };

    const handleSelectAll = () => {
        let selectAll = props.selectAll;
        if (selectAll) {
            setSelectedRows([]);
            props.handleSelectedIndex([]);
        } else {
            setSelectedRows(props.tableData.map(item => item.Email));
            props.handleSelectedIndex(props.tableData.map(item => item.Email));
        }
        props.setSelectAllChange(!selectAll);
    };

    return (
        <>
            <table style={{ width: '108vw' }}>
                <thead>
                    <tr style={tableheader}>
                        <th style={{ width: '10px', backgroundColor: '#eae8e8', border: '1px solid black' }}>
                            <input
                                type="checkbox"
                                checked={props.selectAll}
                                onChange={handleSelectAll}
                            />
                        </th>
                        {tableFormat.headerLabels.map((label, index) => (
                            <th key={index} style={headerStyle}>
                                {label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.tableData?.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                        >
                            <td style={{ backgroundColor: selectedRows.includes(row.Email) ? '#ccd8e0' : '', border: '1px solid black', width: '10px' }}>
                                <input
                                    type="checkbox"
                                    checked={props.selectedIndex?.includes(row.Email)}
                                    onChange={() => { handleRowSelect(row.Email) }}
                                />
                            </td>

                            {tableFormat.keys.map((label, columnIndex) =>
                            (
                                <td
                                    key={columnIndex}
                                    style={{
                                        ...bodyStyle,
                                        backgroundColor: selectedRows.includes(row.Email) ? '#ccd8e0' : '',
                                        border: '1px solid black'
                                    }}
                                >
                                    {row[label]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

const headerStyle = {
    lineHeight: '25.5px',
    letterSpacing: '0px',
    textAlign: 'left',
    height: '30px',
    padding: '10px',
    backgroundColor: '#eae8e8',
    border: '1px solid black'
};

const bodyStyle = {
    lineHeight: '25.5px',
    letterSpacing: '0px',
    textAlign: 'left',
    height: '30px',
    padding: '10px'
};
const tableheader = {
    position: 'sticky',
    top: 0,
    backgroundColor: '#eae8e8',
    border: '1px solid black'
}

export default TableComponent;