import React from "react";
import {
  TextField,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import "./index.css";
import withRouter from "./../../../components/withRouter";
import TimezoneSelect from "react-timezone-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuItem from "@material-ui/core/MenuItem";
import InfoIcon from "@material-ui/icons/Info";
import { Grid } from "@material-ui/core";
import ReactQuill from "react-quill";
import Select from 'react-select';
import "react-quill/dist/quill.snow.css";
import { withMsal } from '@azure/msal-react';
import { compose } from "recompose";

const moment = require("moment");

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "45px",
    height: "45px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "45px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "45px",
  }),
};

const durationOptions = [
  { value: "30 minutes", label: "30 minutes", numericvalue: 30 },
  { value: "45 minutes", label: "45 minutes", numericvalue: 45 },
  { value: "1 hour", label: "1 hour", numericvalue: 60 },
  { value: "1 hr, 15 min", label: "1 hr, 15 min", numericvalue: 75 },
  { value: "1 hr, 30 min", label: "1 hr, 30 min", numericvalue: 90 },
  { value: "1 hr, 45 mins", label: "1 hr, 45 mins", numericvalue: 105 },
  { value: "2 hours", label: "2 hours", numericvalue: 120 },
];

const enableDigitalNative = ["Customer Briefing (non-EBC)", "Roadmap", "Rapid Prototype (POC)", "Technical Workshop", "Architecture design"]


export class DetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openmode: this.props.values.openmode,
      customerName: this.props.values.customerName
        ? this.props.values.customerName
        : "",
      isPartner: this.props.values.isPartner,
      DigitalNative: enableDigitalNative.includes(this.props.values.engagement_type) ? this.props.values.DigitalNative : false,
      DigitalNativeDisabled: enableDigitalNative.includes(this.props.values.engagement_type) ? false : true,
      S500Customer: this.props.values.S500Customer,
      engagementName: this.props.values.engagementName
        ? this.props.values.engagementName
        : "",
      location: this.props.values.location
        ? this.props.values.location
        : "",
      meetingLink: this.props.values.meetingLink
        ? this.props.values.meetingLink
        : "",
      timeNote: this.props.values.timeNote
        ? this.props.values.timeNote
        : "",
      meetingFormat: this.props.values.meetingFormat
        ? this.props.values.meetingFormat
        : "",
      requestedDate: this.props.values.requestedDate
        ? this.props.values.requestedDate
        : "",
      teCoveredBySponser: this.props.values.teCoveredBySponser,
      fromDate: this.props.values.fromDate ? this.props.values.fromDate : "",
      toDate: this.props.values.toDate ? this.props.values.toDate : "",
      timeZone: this.props.values.timeZone ? this.props.values.timeZone : "",
      industry: this.props.values.industry ? { "value": this.props.values.industry, "label": this.props.values.industry } : "",
      accesstoAOAI : this.props.values.accesstoAOAI ? this.props.values.accesstoAOAI  : "",
      aiMaturityLevel: this.props.values.aiMaturityLevel ? { "value": this.props.values.aiMaturityLevel, "label": this.props.values.aiMaturityLevel } : "",
      appliedtoAOAI :  this.props.values.appliedtoAOAI ? this.props.values.appliedtoAOAI : "",
      duration:
        this.props.values.duration && this.props.values.duration !== ""
          ? this.props.values.duration
          : this.props.values.openmode === ""
            ? "1 hour"
            : "",
      maxDate: this.props?.values?.sixWeekWaiting
        ? this.props?.values?.createdOn ? moment(moment.utc(this.props?.values?.createdOn)).add(6, "weeks").format("YYYY-MM-DDTHH:mm") : moment(moment()).add(6, "weeks").format("YYYY-MM-DDTHH:mm")
        : this.props?.values?.createdOn ? moment(moment.utc(this.props?.values?.createdOn)).add(3, "weeks").format("YYYY-MM-DDTHH:mm") : moment(moment()).add(3, "weeks").format("YYYY-MM-DDTHH:mm"),
      errors: {},
    };
    // Bind the handleChange functionF
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    var addtime, caltoDate;
    this.setState({ [event.target.name]: event.target.value });
    this.props.setParameters([event.target.name], event.target.value);
    if (event.target.name === "fromDate" && event.target.value) {
      addtime = durationOptions.find(
        (option) => option.label === this.state.duration
      );
      if (addtime) {
        caltoDate = this.addMinutesToDate(
          event.target.value,
          addtime.numericvalue
        );
        this.setState({ toDate: caltoDate });
        this.props.setParameters("toDate", caltoDate);
      }
    }
    if (
      event.target.name === "duration" &&
      this.state.requestedDate === "Specific"
    ) {
      addtime = durationOptions.find(
        (option) => option.label === event.target.value
      );
      if (addtime && this.state.fromDate !== "") {
        caltoDate = this.addMinutesToDate(
          this.state.fromDate,
          addtime.numericvalue
        );
        this.setState({ toDate: caltoDate });
        this.props.setParameters("toDate", caltoDate);
      }
    }
  };

  handleDropdownChange = (name, option) => {
    this.setState({ [name]: option })
    this.props.setParameters([name], option.value)
  };

  onEditorStateChange = (label, editorState) => {
    if (editorState.replace(/<[^>]+>/g, "")?.trim() === "") {
      editorState = "";
    }
    this.setState({
      [label]: editorState,
    });
    this.props.setParameters([label], editorState);
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    var successfulSignIn = false;
    try {
      const { instance, accounts } = this.props.msalContext;
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: ["User.Read", "openid", "profile", "offline_access", "email", "User.ReadBasic.All"],
          account: accounts ? accounts[0] : null,
        };
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        sessionStorage.setItem("userName", response.account.name);
        sessionStorage.setItem("Emailid", response.account.username);
        sessionStorage.setItem("accountId", response.account.localAccountId);
        sessionStorage.setItem("userToken", response.accessToken);
        this.setState({
          isSignedIn: true
        })
        successfulSignIn = true
      } else {
        throw Error("not found any current active account")
      }
    } catch (error) {
      console.log('Error acquiring token silently:', error);
      sessionStorage.clear();
      window.location.replace('/');
    }
    if (successfulSignIn) {
      this.props.setParameters("DigitalNative", this.state.DigitalNative);
      this.setState({
        location: this.state.location?.includes("<p><br></p>") || this.state.location?.includes("</p><p>")
          ? this.state.location?.replace(/<br>/g, '')?.replace(/<\/p><p>/g, '<br>')
          : this.state.location,
        meetingLink: this.state.meetingLink?.includes("<p><br></p>") || this.state.meetingLink?.includes("</p><p>")
          ? this.state.meetingLink?.replace(/<br>/g, '')?.replace(/<\/p><p>/g, '<br>')
          : this.state.meetingLink,
        timeNote: this.state.timeNote?.includes("<p><br></p>") || this.state.timeNote?.includes("</p><p>")
          ? this.state.timeNote?.replace(/<br>/g, '')?.replace(/<\/p><p>/g, '<br>')
          : this.state.timeNote,
      });
    }
  };

  handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
    this.props.setParameters([name], checked);
  };

  handletimeZoneSelect = (timezone) => {
    this.setState({
      timeZone: timezone,
    });
    this.props.setParameters("timeZone", timezone.value);
    this.props.setParameters("timeZoneLable", timezone.label);
  };

  handleCopyMeetingLink = () => {
    // Logic to copy the meeting link to the clipboard
    // You can use the Clipboard API or a third-party library like "clipboard.js"
  };

  handleRequestedDates = (event) => {
    if (event.target.name === "requestedDate") {
      const isFlexibleDate = event.target.value === "Flexible" ? true : false;
      this.setState({
        requestedDate: event.target.value,
        fromDate: isFlexibleDate ? "" : this.state.fromDate, // Clear fromDate value if "Flexible" is selected
        toDate: isFlexibleDate ? "" : this.state.toDate, // Clear toDate value if "Flexible" is selected
        timeZone: isFlexibleDate
          ? ""
          : this.state.timeZone
            ? this.state.timeZone
            : "America/Los_Angeles",
        duration: isFlexibleDate
          ? ""
          : this.state.duration
            ? this.state.duration
            : "1 hour",
      });
      this.props.setParameters("requestedDate", event.target.value);
      this.props.setParameters(
        "fromDate",
        isFlexibleDate ? "" : this.state.fromDate
      );
      this.props.setParameters(
        "toDate",
        isFlexibleDate ? "" : this.state.toDate
      );
      this.props.setParameters(
        "timeZone",
        isFlexibleDate
          ? ""
          : this.state.timeZone
            ? this.state.timeZone
            : "America/Los_Angeles"
      );
      this.props.setParameters(
        "duration",
        isFlexibleDate
          ? ""
          : this.state.duration
            ? this.state.duration
            : "1 hour"
      );
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
      this.props.setParameters([event.target.name], event.target.value);
    }
  };

  handleMeetingLink = (event) => {
    if (event.target.name === "meetingFormat") {
      // Disable meetingLink field if "In Person" is selected
      const isMeetingInPerson = event.target.value === "In Person";
      const isMeetingRemote = event.target.value === "Remote";
      this.setState((prevState) => ({
        meetingFormat: event.target.value,
        meetingLink: isMeetingInPerson ? "" : prevState.meetingLink, // Clear meetingLink value if "In Person" is selected
        location: isMeetingRemote ? "" : prevState.location,
      }));
      this.props.setParameters("meetingFormat", event.target.value);
      if (isMeetingInPerson) {
        this.props.setParameters("meetingLink", "");
      }
      if (isMeetingRemote) {
        this.props.setParameters("location", "");
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
      this.props.setParameters([event.target.name], event.target.value);
    }
  };

  Previous = () => {
    const { prevStep } = this.props;
    this.props.setParameters(
      "timeZoneLable",
      document.getElementById("timeZone")?.innerText
    );
    prevStep();
  };

  timeNotesPlaceholder = () => {
    if (this.state.requestedDate === "Specific") {
      return "e.g., option to move to the Tuesday at 11am";
    } else if (this.state.requestedDate === "Span") {
      return "e.g., 2-day EBC is on Dec 14th; prefer session to follow Azure Data at 1pm on Thurs";
    } else if (this.state.requestedDate === "Flexible") {
      return "e.g., first available afternoon (EST) any Wednesday works best for the customer";
    } else {
      return "Please provide any notes regarding date, time, or duration flexibility that may increase acceptance.";
    }
  };
  handleSubmit = () => {
    const startOfMaxDate = moment(this.state.maxDate).startOf('day').format("YYYY-MM-DDTHH:mm");
    this.props.setParameters(
      "timeZoneLable",
      document.getElementById("timeZone")?.innerText
    );
    const { nextStep } = this.props;
    const errors = {};
    if (
      this.state.customerName === null ||
      (typeof this.state.customerName === "string" &&
        this.state.customerName?.trim() === "")
    ) {
      errors.customerName = true;
    }

    if (
      this.state.engagementName === null ||
      (typeof this.state.engagementName === "string" &&
        this.state.engagementName?.trim() === "")
    ) {
      errors.engagementName = true;
    }
    if (
      this.state.meetingFormat === null ||
      (typeof this.state.meetingFormat === "string" &&
        this.state.meetingFormat?.trim() === "")
    ) {
      errors.meetingFormat = true;
    }
    if (
      this.state.requestedDate === null ||
      (typeof this.state.requestedDate === "string" &&
        this.state.requestedDate?.trim() === "")
    ) {
      errors.requestedDate = true;
    }
    if (
      (this.state.requestedDate === "Flexible" ||
        this.state.requestedDate === "Span") &&
      (this.state.timeNote === null ||
        (typeof this.state.timeNote === "string" &&
          this.state.timeNote?.trim() === ""))
    ) {
      errors.timeNote = true;
    }
    if (
      this.state.requestedDate === "Specific" &&
      (this.state.fromDate === null ||
        (typeof this.state.fromDate === "string" &&
          this.state.fromDate?.trim() === ""))
    ) {
      errors.fromDate = true;
    } else if (
      (this.state.fromDate !== null ||
        (typeof this.state.fromDate !== "string" &&
          this.state.fromDate?.trim() !== "")) &&
      new Date(startOfMaxDate) > new Date(this.state.fromDate)
    ) {
      errors.fromDate = true;
      errors.highDemandSpeakers = true;
    }

    if (
      (this.state.toDate !== null ||
        (typeof this.state.toDate === "string" &&
          this.state.toDate?.trim() !== "")) &&
      new Date(startOfMaxDate) > new Date(this.state.toDate)
    ) {
      errors.toDate = true;
      errors.highDemandSpeakers = true;
    }

    if (
      this.state.requestedDate === "Specific" &&
      (this.state.timeZone === null ||
        (typeof this.state.timeZone === "string" &&
          this.state.timeZone?.trim() === ""))
    ) {
      errors.timeZone = true;
    }
    if (
      this.state.requestedDate === "Specific" &&
      (this.state.duration === null ||
        (typeof this.state.duration === "string" &&
          this.state.duration?.trim() === ""))
    ) {
      errors.duration = true;
    }
    if (Object.keys(errors).length === 0) {
      this.setState({ errors: {} });
      nextStep();
    } else {
      this.setState({ errors });
      toast.warn("Please fill the required fields highlighted into red!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  addMinutesToDate = (dateString, minutes) => {
    const date = moment(dateString);
    const updatedDate = date.add(minutes, "minutes");
    return updatedDate.format("YYYY-MM-DDTHH:mm");
  };

  render() {
    const { errors } = this.state;
    const industry = ['Retail', 'Healthcare', 'Finance', 'Manufacturing', 'Consumer Goods', 'Automotive', 'Aviation', 'Insurance', 'Media and Entertainment', 'Telecommunications', 'Gaming', 'Sustainability']
    const aiMaturityLevel = ['Beginner (Emerging AI Capability)', 'Intermediate (Developing AI Capability)', 'Advanced (Mature AI Capability)']
    return (
      <form className="form" style={{ width: "100%" }}>
         
        <fieldset disabled={this.state.openmode === "view" ? "disabled" : ""}>
          <Grid
            container
            spacing={0}
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
          >
             <div className="skip-note" style={{paddingBottom : '30px',marginTop : '-15px'}}><span className="bolder">Note:</span> Please feel free to skip fields that do not apply to your request.</div>

            <Grid item xs={12} sm={12}>
              <div className="input-field-with-help-text">
                <div className="label-container">
                  <label
                    htmlFor="customerName"
                    className={`input-label ${errors.customerName ? "required-label" : ""
                      }`}
                  >
                    Customer Name *
                  </label>
                </div>
                <TextField
                  id="customerName"
                  name="customerName"
                  placeholder="Enter Customer Name"
                  value={this.state.customerName}
                  onChange={this.handleChange}
                  error={!!errors.customerName}
                  inputProps={{ maxLength: 100 }}
                  helperText={
                    this.state.customerName !== null &&
                      this.state.customerName?.length > 0
                      ? `${100 - this.state.customerName?.length
                      } characters remaining`
                      : "100 characters remaining"
                  }
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={4} sm={4}>
              <div className="input-field">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isPartner"
                      value={this.state.isPartner}
                      checked={this.state.isPartner}
                      onChange={this.handleCheckBoxChange}
                    />
                  }
                  label={
                    <div
                      className={`input-label ${errors.isPartner ? "required-label" : ""
                        }`}
                    >
                      Customer is a Partner/GISV/ISV
                    </div>
                  }
                />
              </div>
            </Grid>
            <Grid item xs={4} sm={4}>
              <div className="input-field">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="S500Customer"
                      value={this.state.S500Customer}
                      checked={this.state.S500Customer}
                      onChange={this.handleCheckBoxChange}
                    />
                  }
                  label={
                    <div
                      className={`input-label`}
                    >
                      S500 Customer
                    </div>
                  }
                />
              </div>
            </Grid>
            <Grid item xs={4} sm={4}>
              <div className="input-field">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="DigitalNative"
                      value={this.state.DigitalNative}
                      checked={this.state.DigitalNative}
                      onChange={this.handleCheckBoxChange}
                      disabled={this.state.DigitalNativeDisabled}
                    />
                  }
                  label={
                    <div
                      className={`input-label`}
                    >
                      Digital Native/Startup/ISV
                    </div>
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container">
                  <label htmlFor="industry" className="input-label">
                    Industry
                  </label>
                </div>
                <Select
                  id="industry"
                  name="industry"
                  options={industry.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  placeholder="select Industry"
                  styles={customStyles}
                  value={this.state.industry}
                  onChange={(selectedOption) =>
                    this.handleDropdownChange('industry', selectedOption)
                  }
                  classNamePrefix="react-select"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container">
                  <label htmlFor="aiMaturityLevel" className="input-label">
                    AI Maturity Level
                  </label>
                </div>
                <Select
                  id="aiMaturityLevel"
                  name="aiMaturityLevel"
                  options={aiMaturityLevel.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  placeholder="select AI Maturity Level"
                  styles={customStyles}
                  value={this.state.aiMaturityLevel}
                  onChange={(selectedOption) =>
                    this.handleDropdownChange('aiMaturityLevel', selectedOption)
                  }
                  classNamePrefix="react-select"
                />
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
                <div className="input-field" style={{marginBottom :'10px'}}>
                  <div className="radio-button-label-containear">
                    <label htmlFor="appliedtoAOAI" className="input-label">
                    Have they applied to Azure Open AI service?
                    </label>
                  </div>
                  <RadioGroup
                    id="appliedtoAOAI"
                    name="appliedtoAOAI"
                    value={this.state.appliedtoAOAI}
                    onChange={this.handleChange}
                    error={!!errors.appliedtoAOAI}
                  >
                    <div>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                        className="radio-button-label"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </div>
                  </RadioGroup>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <div className="input-field" style={{marginBottom :'5px'}}>
                  <div className="radio-button-label-containear">
                    <label htmlFor="accesstoAOAI" className="input-label">
                    Have they received access to Azure Open AI service?                    </label>
                  </div>
                  <RadioGroup
                    id="accesstoAOAI"
                    name="accesstoAOAI"
                    value={this.state.accesstoAOAI}
                    onChange={this.handleChange}
                    error={!!errors.accesstoAOAI}
                  >
                    <div>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                        className="radio-button-label"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </div>
                  </RadioGroup>
                </div>
              </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field-with-help-text" style={{marginTop : '5px'}}>
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="engagementName"
                    className={`input-label ${errors.engagementName ? "required-label" : ""
                      }`}
                  >
                    Engagement Name *
                    <Tooltip
                      className="tooltip-background"
                      title="We will use this to reference the engagement by a common name in our communication with you."
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <TextField
                  id="engagementName"
                  name="engagementName"
                  placeholder="Enter Engagement Name"
                  value={this.state.engagementName}
                  onChange={this.handleChange}
                  error={!!errors.engagementName}
                  inputProps={{ maxLength: 100 }}
                  helperText={
                    this.state.engagementName !== null &&
                      this.state.engagementName?.length > 0
                      ? `${100 - this.state.engagementName?.length
                      } characters remaining`
                      : "100 characters remaining"
                  }
                  fullWidth
                  style={{ width: "100%" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="radio-button-label-containear">
                  <label
                    htmlFor="meetingFormat"
                    className={`input-label ${errors.meetingFormat ? "required-label" : ""
                      }`}
                  >
                    Meeting Format *
                    <Tooltip
                      className="tooltip-background"
                      title="We will use this to identify speakers who can accept in the correct format."
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <div className="meeting-with-sponser">
                  <RadioGroup
                    id="meetingFormat"
                    name="meetingFormat"
                    value={this.state.meetingFormat}
                    onChange={this.handleMeetingLink} // Updated event handler function
                    error={!!errors.meetingFormat}
                  >
                    <div>
                      <FormControlLabel
                        value="In Person"
                        control={<Radio />}
                        label={
                          <div>
                            In Person{" "}
                            <Tooltip
                              className="tooltip-background"
                              title="Attendees will join on site. Speakers are expected in person."
                            >
                              <IconButton
                                className="tooltip-icon-button"
                                aria-label="info"
                              >
                                <InfoIcon className="tooltip-icon" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        }
                        className="radio-button-label"
                      />
                      <FormControlLabel
                        value="Hybrid"
                        control={<Radio />}
                        label={
                          <div>
                            Hybrid
                            <Tooltip
                              className="tooltip-background"
                              title="Attendees will join on site with some virtual. Speakers are preferred on site."
                            >
                              <IconButton
                                className="tooltip-icon-button"
                                aria-label="info"
                              >
                                <InfoIcon className="tooltip-icon" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        }
                      />
                      <FormControlLabel
                        value="Remote"
                        control={<Radio />}
                        label={
                          <div>
                            Remote{" "}
                            <Tooltip
                              className="tooltip-background"
                              title="Attendees and speakers will meet virtually."
                            >
                              <IconButton
                                className="tooltip-icon-button"
                                aria-label="info"
                              >
                                <InfoIcon className="tooltip-icon" />
                              </IconButton>
                            </Tooltip>{" "}
                          </div>
                        }
                      />
                    </div>
                  </RadioGroup>
                  {this.state.meetingFormat === "In Person" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="teCoveredBySponser"
                          value={this.state.teCoveredBySponser}
                          checked={this.state.teCoveredBySponser}
                          onChange={this.handleCheckBoxChange}
                        />
                      }
                      label={
                        <div className="input-label">
                          T&E covered by sponsor
                        </div>
                      }
                    />
                  )}
                </div>
                {errors.meetingFormat && (
                  <span className="error-message">{errors.meetingFormat}</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="location"
                    className={`input-label ${errors.location ? "required-label" : ""
                      }`}
                  >
                    Location
                    <Tooltip
                      className="tooltip-background"
                      title="Share the physical location, e.g., address, building, meeting room."
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                {/* <TextField
                id="location"
                name="location"
                placeholder="Enter Location"
                value={this.state.location}
                onChange={this.handleChange}
                inputProps={{ maxLength: 100 }}
                helperText={(this.state.location!==null && this.state.location?.length > 0) ? `${100-this.state.location?.length} characters remaining` : '100 characters remaining'}  
                fullWidth
                multiline
                rows={4}
                disabled={this.state.meetingFormat === "Remote"} // Disable when "Remote" is selected
              /> */}
                <div className="richtext-containear">
                  <ReactQuill
                    id="location"
                    placeholder="Enter Location"
                    theme="snow"
                    className={`${this.state.meetingFormat === "Remote" ? 'disable-rich-text' : ''}`}
                    value={this.state.location}
                    onChange={(editorState) => {
                      this.onEditorStateChange("location", editorState);
                    }}
                    disabled={this.state.openmode === "view" ? "disabled" : ""}
                    readOnly={
                      this.state.openmode === "view" ||
                        this.state.meetingFormat === "Remote"
                        ? true
                        : false
                    }
                  />
                  {/* <div className='helper-text-containear'><div className='helper-text'>{this.state.customerObjectives === '' ? '10000 characters remaining' : `${10000 - document.getElementById("customerObjectives")?.innerText?.length} characters remaining`}</div></div> */}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field-with-help-text">
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="meetingLink"
                    className={`input-label ${errors.meetingLink ? "required-label" : ""
                      }`}
                  >
                    Meeting Link
                    <Tooltip
                      className="tooltip-background"
                      title="[Optional] Share the meeting link if it already exists."
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <div style={{ position: "relative" }}>
                  {/* <TextField
                  id="meetingLink"
                  name="meetingLink"
                  placeholder="Enter Meeting Link"
                  value={this.state.meetingLink}
                  onChange={this.handleChange}
                  error={!!errors.meetingLink}
                  inputProps={{ maxLength: 100 }}
                  helperText={(this.state.meetingLink!==null && this.state.meetingLink?.length > 0) ? `${100-this.state.meetingLink?.length} characters remaining` : '100 characters remaining'}  
                  fullWidth
                  multiline
                  rows={4}
                  disabled={this.state.meetingFormat === "In Person"} // Disable the field if "In Person" is selected
                /> */}
                  <div className="richtext-containear">
                    <ReactQuill
                      id="meetingLink"
                      theme="snow"
                      placeholder="Enter Meeting Link"
                      className={`${this.state.meetingFormat === "In Person" ? 'disable-rich-text' : ''}`}
                      value={this.state.meetingLink}
                      onChange={(editorState) => {
                        this.onEditorStateChange("meetingLink", editorState);
                      }}
                      disabled={
                        this.state.openmode === "view" ? "disabled" : ""
                      }
                      readOnly={
                        this.state.openmode === "view" ||
                          this.state.meetingFormat === "In Person"
                          ? true
                          : false
                      }
                    />
                    {/* <div className='helper-text-containear'><div className='helper-text'>{this.state.customerObjectives === '' ? '10000 characters remaining' : `${10000 - document.getElementById("customerObjectives")?.innerText?.length} characters remaining`}</div></div> */}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field" style={{ marginTop: "18px" }}>
                <div className="radio-button-label-containear">
                  <label
                    htmlFor="requestedDate"
                    className={`input-label ${errors.requestedDate ? "required-label" : ""
                      }`}
                  >
                    Requested Date *
                    <Tooltip
                      className="tooltip-background"
                      title="This helps us determine speaker availability."
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <RadioGroup
                  id="requestedDate"
                  name="requestedDate"
                  value={this.state.requestedDate}
                  onChange={this.handleRequestedDates}
                  error={!!errors.requestedDate}
                >
                  <div>
                    <FormControlLabel
                      value="Specific"
                      control={<Radio />}
                      label={
                        <div>
                          Specific
                          <Tooltip
                            className="tooltip-background"
                            title="Date and time has already been established before this request."
                          >
                            <IconButton
                              className="tooltip-icon-button"
                              aria-label="info"
                            >
                              <InfoIcon className="tooltip-icon" />
                            </IconButton>
                          </Tooltip>{" "}
                        </div>
                      }
                    />
                    <FormControlLabel
                      value="Span"
                      control={<Radio />}
                      label={
                        <div>
                          Span
                          <Tooltip
                            className="tooltip-background"
                            title="Flexibility on an agenda already established either in a range of days or hours."
                          >
                            <IconButton
                              className="tooltip-icon-button"
                              aria-label="info"
                            >
                              <InfoIcon className="tooltip-icon" />
                            </IconButton>
                          </Tooltip>{" "}
                        </div>
                      }
                    />
                    <FormControlLabel
                      value="Flexible"
                      control={<Radio />}
                      label={
                        <div>
                          Flexible
                          <Tooltip
                            className="tooltip-background"
                            title="Date or time has not been established. Will agree to date or time upon speaker acceptance."
                          >
                            <IconButton
                              className="tooltip-icon-button"
                              aria-label="info"
                            >
                              <InfoIcon className="tooltip-icon" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      }
                    />
                  </div>
                </RadioGroup>
                {errors.requestedDate && (
                  <span className="error-message">{errors.requestedDate}</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="timeNote"
                    className={`input-label ${errors.timeNote ? "required-label" : ""
                      }`}
                  >
                    {`Time Notes ${this.state.requestedDate !== "Specific" ? "*" : ""
                      }`}
                    <Tooltip
                      className="tooltip-background"
                      title="Clarify date or time flexibility, i.e., date is confirmed but we have time flexibility in the morning."
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                {/* <TextField
                id="timeNote"
                name="timeNote"
                placeholder={this.timeNotesPlaceholder()}
                value={this.state.timeNote}
                onChange={this.handleChange}
                inputProps={{ maxLength: 400 }}
                helperText={(this.state.timeNote!==null && this.state.timeNote?.length > 0) ? `${400-this.state.timeNote?.length} characters remaining` : '400 characters remaining'}  
                fullWidth
                multiline
                rows={4}
              /> */}
                <div className="richtext-containear">
                  <ReactQuill
                    id="timeNote"
                    theme="snow"
                    placeholder={this.timeNotesPlaceholder()}
                    value={this.state.timeNote}
                    onChange={(editorState) => {
                      this.onEditorStateChange("timeNote", editorState);
                    }}
                    disabled={this.state.openmode === "view" ? "disabled" : ""}
                    readOnly={this.state.openmode === "view" ? true : false}
                  />
                  {/* <div className='helper-text-containear'><div className='helper-text'>{this.state.customerObjectives === '' ? '10000 characters remaining' : `${10000 - document.getElementById("customerObjectives")?.innerText?.length} characters remaining`}</div></div> */}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="dateDiv">
                <div
                  className="input-field"
                  style={{ width: "50%", paddingRight: "1.5%" }}
                >
                  <div className="label-container">
                    <label
                      htmlFor="fromDate"
                      className={`input-label ${errors.fromDate ? "required-label" : ""
                        }`}
                    >
                      {`From Date/Time ${this.state.requestedDate === "Specific" ? "*" : ""
                        }`}
                    </label>
                  </div>
                  <TextField
                    id="fromDate"
                    name="fromDate"
                    type="datetime-local"
                    value={this.state.fromDate}
                    onChange={this.handleChange}
                    error={!!errors.fromDate}
                    helperText={errors.fromDate}
                    disabled={this.state.requestedDate === "Flexible"} // Disable if requestedDate is "Flexible"
                    inputProps={{ min: this.state.maxDate }}
                  />
                </div>

                <div
                  className="input-field"
                  style={{ width: "50%", paddingLeft: "1.5%" }}
                >
                  <div className="label-container">
                    <label
                      htmlFor="toDate"
                      className={`input-label ${errors.toDate ? "required-label" : ""
                        }`}
                    >
                      To Date/Time
                    </label>
                  </div>
                  <TextField
                    id="toDate"
                    name="toDate"
                    type="datetime-local"
                    value={this.state.toDate}
                    onChange={this.handleChange}
                    error={!!errors.toDate}
                    helperText={errors.toDate}
                    disabled={
                      this.state.requestedDate === "Specific" ||
                      this.state.requestedDate === "Flexible"
                    } // Disable if requestedDate is "Specific"
                    InputLabelProps={{
                      shrink: true,
                      min: this.state.maxDate,
                    }}
                  />
                </div>
              </div>
            </Grid>
            <div className="date-note">
              Note: 3 weeks lead time required for non-executive speakers, 6 weeks for executives.
              {errors.highDemandSpeakers && (
                <div className="error-note-speaker">
                  {this.props?.values?.sixWeekWaiting ? "Please allow a lead time of 6 weeks for executive requests. If this is an urgent request, please reach out to Engage AI" : "Please allow a lead time of 3 weeks for requests. If this is an urgent request, please reach out to Engage AI"}
                </div>
              )}
            </div>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="timeZone"
                    className={`input-label ${errors.timeZone ? "required-label" : ""
                      }`}
                  >
                    {`Time Zone ${this.state.requestedDate === "Specific" ? "*" : ""
                      }`}
                    <Tooltip
                      className="tooltip-background"
                      title="We want to find a speaker that can meet our customers where they are at."
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <TimezoneSelect
                  id="timeZone"
                  name="timeZone"
                  placeholder="Select timezone"
                  styles={customStyles}
                  value={this.state.timeZone}
                  onChange={this.handletimeZoneSelect}
                  error={!!errors.timeZone}
                  helperText={errors.timeZone}
                  isDisabled={this.state.requestedDate === "Flexible"} // Disable if requestedDate is "Flexible"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="duration"
                    className={`input-label ${errors.duration ? "required-label" : ""
                      }`}
                  >
                    {`Duration ${this.state.requestedDate === "Specific" ? "*" : ""
                      }`}
                    <Tooltip
                      className="tooltip-background"
                      title="Tell us how long you anticipate the session should be."
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <TextField
                  id="duration"
                  name="duration"
                  select
                  placeholder="Select duration"
                  // placeholder="Enter duration"
                  value={this.state.duration}
                  onChange={this.handleChange}
                  error={!!errors.duration}
                  helperText={errors.duration}
                  fullWidth
                  disabled={
                    this.state.requestedDate === "Flexible" ||
                    this.state.openmode === "view"
                  } // Disable if requestedDate is "Flexible"
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 250,
                          marginTop: 10,
                        },
                      },
                    },
                  }}
                >
                  {durationOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
          </Grid>
        </fieldset>
        {/* Buttons */}
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
            <div className="details-buttons-container">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.Previous}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleSubmit}
              >
                Next
              </Button>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </form>
    );
  }
}

export default compose(
  withRouter,
  withMsal
)(DetailForm);
