import React, { Component } from "react";
import withRouter from "./../../../components/withRouter";
import "./index.css";
import { Button } from "@material-ui/core";
import moment from "moment";
import { withMsal } from "@azure/msal-react";
import { compose } from "recompose";

export class ReviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openmode: this.props.values.openmode,
      customerName: "",
      engagementType: "",
      engagementName: "",
      location: "",
      meetingLink: "",
      timeNote: "",
      meetingFormat: "",
      requestedDate: "",
      fromDate: "",
      toDate: "",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      duration: "",
      opportunityId: "",
      customerAttendees: [],
      technicalDepth: "",
      serviceInterests: null,
      engagementFocus: "",
      microsoftObjectives: "",
      files: [],
      notSalesOpportunity: false,
      attachment: null,
      aiTopic: "",
      customerObjectives: "",
      isPopupOpen: false,
    };
  }
  togglePopup = () => {
    this.setState((prevState) => ({
      isPopupOpen: !prevState.isPopupOpen,
    }));
  };

  Previous = () => {
    const { prevStep } = this.props;
    prevStep();
  };

  submitted = () => {
    // this.togglePopup();
    this.props.create();
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    try {
      const { instance, accounts } = this.props.msalContext;
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: [
            "User.Read",
            "openid",
            "profile",
            "offline_access",
            "email",
            "User.ReadBasic.All",
          ],
          account: accounts ? accounts[0] : null,
        };
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        sessionStorage.setItem("userName", response.account.name);
        sessionStorage.setItem("Emailid", response.account.username);
        sessionStorage.setItem("accountId", response.account.localAccountId);
        sessionStorage.setItem("userToken", response.accessToken);
        this.setState({
          isSignedIn: true,
        });
      } else {
        throw Error("not found any current active account");
      }
    } catch (error) {
      console.log("Error acquiring token silently:", error);
      sessionStorage.clear();
      window.location.replace("/");
    }
  };

  render() {
    return (
      <div className="review-form">
        <div className="title-container">
          <div className="title-review">DETAILS</div>
        </div>
        <div className="details-div">
          <div className="details-label">Type:</div>
          <div className="details-value">
            {`${this.props.values.request_type} | ${this.props.values.engagement_type}`}
          </div>
        </div>
        <div className="details-div">
          <div className="details-label">Customer Name:</div>
          <div className="details-value">
            {`${this.props.values.customerName} ${
              this.props.values?.isPartner
                ? "| Customer is a Partner/GISV/ISV"
                : ""
            } ${this.props.values?.S500Customer ? "| S500" : ""} ${
              this.props.values?.DigitalNative
                ? "| Digital Native/Startup/ISV"
                : ""
            }`}
          </div>
        </div>
        {this.props.values.industry && this.props.values.industry !== "" && (
          <div className="details-div">
            <div className="details-label">Industry:</div>
            <div className="details-value">{this.props.values.industry}</div>
          </div>
        )}
        <div className="details-div">
          <div className="details-label">AI Maturity Level:</div>
          <div className="details-value">
            {`${
              this.props.values.aiMaturityLevel &&
              this.props.values.aiMaturityLevel !== ""
                ? this.props.values.aiMaturityLevel
                : ""
            } ${
              this.props.values.appliedtoAOAI === "Yes"
                ? this.props.values.aiMaturityLevel &&
                  this.props.values.aiMaturityLevel !== ""
                  ? "| Applied to AOAI"
                  : "Applied to AOAI"
                : ""
            } ${
              this.props.values.accesstoAOAI === "Yes"
                ? (this.props.values.aiMaturityLevel &&
                    this.props.values.aiMaturityLevel !== "") ||
                  this.props.values.appliedtoAOAI === "Yes"
                  ? "| Received access to AOAI"
                  : "Received access to AOAI"
                : ""
            }`}
          </div>
        </div>
        <div className="details-div">
          <div className="details-label">Title:</div>
          <div className="details-value">
            {this.props.values.engagementName}
          </div>
        </div>
        <div className="details-div">
          <div className="details-label">Format:</div>
          <div className="details-value">
            {`${this.props.values.meetingFormat} ${
              this.props.values.teCoveredBySponser
                ? "| T&E covered by sponsor"
                : ""
            }`}
          </div>
        </div>
        {this.props.values.location && this.props.values.location !== "" && (
          <div className="details-div">
            <div className="details-label">Location:</div>
            <div
              className="details-value rich-text-wrap"
              dangerouslySetInnerHTML={{ __html: this.props.values.location }}
              style={{ marginTop: "-15px" }}
            ></div>
          </div>
        )}
        {/* {(this.props.values.executive_speaker_name && this.props.values.executive_speaker_name!=="") && <div className="details-div">
                    <div className="details-label">
                        executive speaker:
                    </div>
                    <div className="details-value">
                        {this.props.values.executive_speaker_name}
                    </div>
                </div> } */}
        {this.props.values.meetingLink &&
          this.props.values.meetingLink !== "" && (
            <div className="details-div">
              <div className="details-label">Meeting link:</div>
              <div
                className="details-value rich-text-wrap"
                dangerouslySetInnerHTML={{
                  __html: this.props.values.meetingLink,
                }}
                style={{ marginTop: "-15px" }}
              ></div>
            </div>
          )}
        <div className="details-div">
          <div className="details-label">Date requested:</div>
          <div className="details-value">{this.props.values.requestedDate}</div>
        </div>
        {this.props.values.timeNote && this.props.values.timeNote !== "" && (
          <div className="details-div">
            <div className="details-label">Note:</div>
            <div
              className="details-value rich-text-wrap"
              dangerouslySetInnerHTML={{ __html: this.props.values.timeNote }}
              style={{ marginTop: "-15px" }}
            ></div>
          </div>
        )}

        {(this.props.values.requestedDate === "Span" ||
          this.props.values.requestedDate === "Specific") && (
          <div className="details-div">
            <div className="details-label">Date:</div>

            {this.props.values.requestedDate === "Span" && (
              <div className="details-value">
                {`${
                  this.props.values.fromDate &&
                  this.props.values.fromDate !== ""
                    ? moment(this.props.values.fromDate).format(
                        "M/D/YYYY h:mm A"
                      )
                    : ""
                } ${
                  this.props.values.toDate && this.props.values.toDate !== ""
                    ? " - " +
                      moment(this.props.values.toDate).format("M/D/YYYY h:mm A")
                    : ""
                } ${
                  this.props.values.timeZone
                    ? " - " + this.props.values.timeZoneLable
                    : ""
                } ${
                  this.props.values.duration
                    ? " | " + this.props.values.duration
                    : ""
                }`}
              </div>
            )}
            {this.props.values.requestedDate === "Specific" && (
              <div className="details-value">
                {`${
                  this.props.values.fromDate &&
                  this.props.values.fromDate !== ""
                    ? moment(this.props.values.fromDate).format(
                        "M/D/YYYY h:mm A"
                      )
                    : ""
                }  ${
                  this.props.values.timeZone
                    ? " - " + this.props.values.timeZoneLable
                    : ""
                } ${
                  this.props.values.duration
                    ? " | " + this.props.values.duration
                    : ""
                }`}
              </div>
            )}
          </div>
        )}
        <div className="title-container" style={{ paddingTop: "42px" }}>
          <div className="title-review">OBJECTIVE</div>
        </div>
        {this.props.values.linkedInBio &&
          this.props.values.linkedInBio !== "" && (
            <div className="details-div">
              <div className="details-label">LinkedIn Bios:</div>
              <div
                className="details-value rich-text-wrap"
                dangerouslySetInnerHTML={{
                  __html: this.props.values.linkedInBio,
                }}
                style={{ marginTop: "-15px" }}
              ></div>
            </div>
          )}
        <div className="details-div">
          <div className="details-label">Attendees:</div>
          <div className="details-value">
            {this.props.values.customerAttendees.join(", ")}
          </div>
        </div>
        <div className="details-div">
          <div className="details-label">Technical depth:</div>
          <div className="details-value">
            {this.props.values.technicalDepth}
          </div>
        </div>
        <div className="details-div">
          <div className="details-label">Topic:</div>
          <div className="details-value">{this.props.values.aiTopic}</div>
        </div>
        {this.props.values.serviceInterests &&
          this.props.values.serviceInterests !== "" && (
            <div className="details-div">
              <div className="details-label">Service Interest:</div>
              <div className="details-value">
                {this.props.values?.serviceInterests}
              </div>
            </div>
          )}

        {this.props.values.customTopicInterest &&
          this.props.values.customTopicInterest !== "" && (
            <div className="details-div">
              <div className="details-label">Custom Topic Title:</div>
              <div className="details-value">
                {this.props.values?.customTopicInterest}
              </div>
            </div>
          )}

        {this.props.values.timeHorizon &&
          this.props.values.timeHorizon !== "" && (
            <div className="details-div">
              <div className="details-label">
                Expected time for this workload to reach production:
              </div>
              <div className="details-value">
                {this.props.values?.timeHorizon}
              </div>
            </div>
          )}

        <div className="details-div">
          <div className="details-label">Engagement Focus:</div>
          <div
            className="details-value rich-text-wrap"
            dangerouslySetInnerHTML={{
              __html: this.props.values.engagementFocus,
            }}
            style={{ marginTop: "-15px" }}
          ></div>
        </div>
        <div className="details-div">
          <div className="details-label">Microsoft Objectives:</div>
          <div
            className="details-value rich-text-wrap"
            dangerouslySetInnerHTML={{
              __html: this.props.values.microsoftObjectives,
            }}
            style={{ marginTop: "-15px" }}
          ></div>
        </div>
        <div className="details-div">
          <div className="details-label">Customer Objectives:</div>
          <div
            className="details-value rich-text-wrap"
            dangerouslySetInnerHTML={{
              __html: this.props.values.customerObjectives,
            }}
            style={{ marginTop: "-15px" }}
          ></div>
        </div>
        <div className="buttons-container" style={{ marginTop: "20px" }}>
          <Button variant="outlined" color="primary" onClick={this.Previous}>
            Previous
          </Button>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            onClick={this.submitted}
            disabled={this.state.openmode === "view" ? "disabled" : ""}
          >
            {this.state.openmode === "edit" ? "update" : "Submit"}
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(ReviewForm);
