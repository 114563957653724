import React, { Component } from "react";
import withRouter from "./../../../components/withRouter";
import "./index.css";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import { toast, ToastContainer } from 'react-toastify';
import { withMsal } from '@azure/msal-react';
import { compose } from "recompose";

import {
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@material-ui/core";
import customerBriefing from "./../../../assets/customerBriefing.png";
import roadMap from "./../../../assets/Roadmap.png";
import technicalWorkshop from "./../../../assets/TechnicalWorkshop.png";
import archtiecturedesign from "./../../../assets/architecturedesign.png";
import rapid from "./../../../assets/Prototype.png";
import { InputAdornment, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DoneIcon from "@material-ui/icons/Done";
import axios from "axios";
import config from "./../../../config/config";
import externalConference from "./../../../assets/externalConference.png";
import internalConference from "./../../../assets/internalConference.png";

const engagementTypeArray = [
  {
    label: "Customer Briefing (non-EBC)",
    value: "Customer Briefing (non-EBC)",
    subtitle:
      "A generic customer engagement requiring a specific speaker, product leader, or executive.",
    image: customerBriefing,
  },
  {
    label: "RoadMap",
    value: "Roadmap",
    subtitle:
      "A specific discussion on the services and capabilities that are generally available, in public preview, or in private preview where we have permission to share.",
    image: roadMap,
  },
  {
    label: "Rapid Protoype (POC)",
    value: "Rapid Prototype (POC)",
    subtitle:
      "An engagement to deliver a working prototype based upon a pre-defined scope and success criteria.",
    image: rapid,
  },
  {
    label: "Technical Workshop",
    value: "Technical Workshop",
    subtitle:
      "Co-create innovative business opportunities that serve as a blueprint for digital transformation and design thinking concepts.",
    image: technicalWorkshop,
  },
  {
    label: "Architecture design",
    value: "Architecture design",
    subtitle:
      "Synthesizes the business and technical requirements for a solution including an initial scope and a high-level architecture to drive next steps.",
    image: archtiecturedesign,
  },
  {
    label: "Internal Microsoft Event/Conference",
    value: "Internal Microsoft Event/Conference",
    subtitle:
      "Request for AI/ML expert  or leader to speak at event or conference",
    image: internalConference,
  },
  {
    label: "External Event/Conference",
    value: "External Event/Conference",
    subtitle:
      "Request for AI/ML expert  or leader to speak at event or conference",
    image: externalConference,
  },
];

export class CreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openmode: this.props.values.openmode,
      engagementType:
        this.props.values.engagement_type &&
          this.props.values.engagement_type !== ""
          ? engagementTypeArray.find(
            (item) => item.value === this.props.values.engagement_type
          )
          : engagementTypeArray[0],
      togglechecked: this.props.values.executive_speaker_required,
      searchInput: this.props.values.executive_speaker_name ? this.props.values.executive_speaker_name : "",
      selectedUserCard: "",
      speakerData: [],
      errors: {},
      speaker_name: this.props.values.executive_speaker_name ? this.props.values.executive_speaker_name : ""
    };
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handletoggleChange = this.handletoggleChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleDropdownChange = (engagementType) => {
    this.setState({ engagementType });
    this.props.setParameters("engagement_type", engagementType.value);
    this.props.setParameters("executive_speaker_id", "");
    this.props.setParameters("executive_speaker_name", "");
    this.props.setParameters("executive_speaker_accountid", "");
    this.props.setParameters("executive_speaker_required", false);
    this.props.setParameters("sixWeekWaiting", false);
    this.setState({
      speakerData: [],
      searchInput: "",
      speaker_name: "",
      executive_speaker_required: false,
      togglechecked: false,
      errors: {},
    });
  };

  handletoggleChange = (event) => {
    const togglechecked = JSON.parse(event.target.value);
    this.setState({ togglechecked });
    this.props.setParameters("executive_speaker_required", togglechecked);
    if (togglechecked === false) {
      this.props.setParameters("executive_speaker_id", "");
      this.props.setParameters("executive_speaker_name", "");
      this.props.setParameters("executive_speaker_accountid", "");
      this.props.setParameters("sixWeekWaiting", false);
      this.setState({
        speakerData: [],
        searchInput: "",
        speaker_name: ""
      });
    }
  };

  handleSearchChange = (event) => {
    this.setState({
      searchInput: event.target.value,
    });
    if (event.target.value && event.target.value.trim() !== "") {
      this.fetchData();
    } else {
      this.setState({
        speakerData: [],
      });
    }
  };

  handleUserCardClick = (item) => {
    this.setState({
      selectedUserCard: item.mail,
    });
    if (this.props?.values?.executive_speaker_required !== item.mail && this?.props?.values?.openmode === "view") {
      this.props.setParameters("changedSpeaker", true);
    } else if (this.props?.values?.executive_speaker_required === item.mail && this?.props?.values?.openmode === "view") {
      this.props.setParameters("changedSpeaker", false);
    }
    this.props.setParameters("executive_speaker_id", item.mail);
    this.props.setParameters("executive_speaker_name", item.displayName);
    this.props.setParameters("executive_speaker_accountid", item.localId);
    if (this.props?.values?.highDemandSpeakers.some(email => email.toLowerCase() === item.mail?.toLowerCase())) {
      this.props.setParameters("sixWeekWaiting", true);
    } else {
      this.props.setParameters("sixWeekWaiting", false);
    }
    this.setState({
      searchInput: item.displayName,
      speaker_name: item.displayName,
    });
  };

  Continue = () => {
    const errors = {}
    if (this.state.togglechecked === true && ((this.state.speaker_name === undefined || this.state.speaker_name === null) || (typeof this.state.speaker_name === 'string' && this.state.speaker_name?.trim() === ''))) {
      errors.togglechecked = true
    }
    if (Object.keys(errors).length === 0) {
      this.setState({ errors: {} });
      const { nextStep } = this.props;
      nextStep();
    } else {
      this.setState({ errors });
      toast.warn('Please fill the required fields highlighted into red!', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  Previous = () => {
    this.props.previous();
  };

  fetchData = async () => {
    var successfulSignIn = false;
    var accessToken = ''
    try {
      const { instance, accounts } = this.props.msalContext;
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: ["User.Read","openid", "profile", "offline_access","email","User.ReadBasic.All"],
          account: accounts ? accounts[0] : null,
        };
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        sessionStorage.setItem("userName", response.account.name);
        sessionStorage.setItem("Emailid", response.account.username);
        sessionStorage.setItem("accountId", response.account.localAccountId);
        accessToken = response.accessToken;
        sessionStorage.setItem("userToken", response.accessToken);
        this.setState({
          isSignedIn: true
        })
        successfulSignIn = true
      } else {
        throw Error("not found any current active account")
      }
    } catch (error) {
      console.log('Error acquiring token silently:', error);
      sessionStorage.clear();
      window.location.replace('/');
    }
    if (successfulSignIn) {
      const url =
        `https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(mail,'@microsoft.com') and (startsWith(displayName,'${this.state.searchInput}') or startsWith(mail,'${this.state.searchInput}'))`;
      const headers = {
        ConsistencyLevel: "eventual",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get(url, { headers });
        this.setState({
          speakerData: response.data.value,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    var successfulSignIn = false;
    try {
      const { instance, accounts } = this.props.msalContext;
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: ["User.Read","openid", "profile", "offline_access","email","User.ReadBasic.All"],
          account: accounts ? accounts[0] : null,
        };
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        sessionStorage.setItem("userName", response.account.name);
        sessionStorage.setItem("Emailid", response.account.username);
        sessionStorage.setItem("accountId", response.account.localAccountId);
        sessionStorage.setItem("userToken", response.accessToken);
        this.setState({
          isSignedIn: true
        })
        successfulSignIn = true
      } else {
        throw Error("not found any current active account")
      }
    } catch (error) {
      console.log('Error acquiring token silently:', error);
      sessionStorage.clear();
      window.location.replace('/');
    }
    if (successfulSignIn) {
      if ((this.props?.workitemData !== null) && (this.props?.values?.attendeeFetched === false)) {
        try {
          const url =
            `${config.apiBaseUrl}/request/attendees/` +
            sessionStorage.getItem("accountId") +
            "/" +
            this.props?.workitemData?.data?.fields["Custom.RequestID"];

          const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.data) {
            this.setState({
              searchInput: response.data?.data?.attendees?.value[0]?.fields["Custom.AttendeeName"],
              speaker_name: response.data?.data?.attendees?.value[0]?.fields["Custom.AttendeeName"]
            });
            this.props.setParameters(
              "executive_speaker_name",
              response.data?.data?.attendees?.value[0]?.fields[
              "Custom.AttendeeName"
              ]
            );
            this.props.setParameters(
              "executive_speaker_id",
              response.data?.data?.attendees?.value[0]?.fields[
              "Custom.AttendeeEmail"
              ]
            );

            if (this.props?.values?.highDemandSpeakers.some(email => email.toLowerCase() === response.data?.data?.attendees?.value[0]?.fields["Custom.AttendeeEmail"]?.toLowerCase())) {
              this.props.setParameters("sixWeekWaiting", true);
            }
            this.props.setParameters(
              "executive_speaker_accountid",
              response.data?.data?.attendees?.value[0]?.fields[
              "Custom.AttendeeID"
              ]
            );
            this.props.setParameters(
              "executive_speaker_workitem",
              response.data?.data?.attendees?.value[0]?.id
            );
            this.props.setParameters(
              "attendeeFetched",
              true
            );
          }
        } catch (error) {
        }
      }
    }
  };
  render() {
    const toggle = this.state.togglechecked;
    const { errors } = this.state;
    const customSelectStyles = {
      singleValue: (provided) => ({
        ...provided,
        fontSize: "16px",
        fontFamily: "Segoe UI",
        letterSpacing: "0px",
        color: "#2D323A",
        fontWeight: "400",
      }),
    };
    return (
      <div className="createForm">
         {/* <div  className="skip-note" style={{paddingBottom:'30px',marginTop:'-35px'}}><span className="bolder">Note:</span> Please feel free to skip fields that do not apply to your request.</div> */}
        <Grid container spacing={2}>
          <Grid item xs={7.5} className="leftGrid">
            <div className="description">
              Please select an Engagement Type for this 'Strategic Customer Engagement or Event' Request.
            </div>
            <div className="dropdown-div" style={{ marginTop: "5px" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Select
                    value={this.state.engagementType}
                    onChange={this.handleDropdownChange}
                    options={engagementTypeArray}
                    className="dropdown-box"
                    styles={customSelectStyles}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="cardContainear">
              <Grid container>
                <Grid item xs={11}>
                  {this.state.engagementType && (
                    <Card className="card">
                      <CardMedia
                        className="card-image"
                        component="img"
                        alt={this.state.engagementType.label}
                        src={this.state.engagementType.image}
                      />
                      <CardContent className="card-content">
                        <h3 className="card-title">
                          {this.state.engagementType.label}
                        </h3>
                        <p className="card-subtitle">
                          {this.state.engagementType.subtitle}
                        </p>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>

          {(this.state.engagementType.value === "Customer Briefing (non-EBC)" ||
            this.state.engagementType.value === "External Event/Conference" ||
            this.state.engagementType.value ===
            "Internal Microsoft Event/Conference") && (
              <Grid item xs={4.5} className="rightGrid">
                <div className="rightGridDiv">
                  <div className="input-field">
                    <div>
                      <label
                        htmlFor="togglechecked"
                        className="input-label"
                      >
                        Does this Strategic Engagement require an executive leader or special speaker? *
                      </label>
                    </div>
                    <div style={{marginTop : '0.7rem'}}>
                      <label className="exec-speaker-radio" style={{font : 'normal normal normal 16px Segoe UI !important'}}>
                        <input
                          type="radio"
                          value={true}
                          checked={this.state.togglechecked === true || this.state.togglechecked === "true"}
                          onChange={this.handletoggleChange}
                          className="strategic-engagement-radio"
                        />
                        Yes
                      </label>
                      <label className="exec-speaker-radio" style={{marginLeft:'0.7rem',font : 'normal normal normal 16px Segoe UI !important'}}>
                        <input
                          type="radio"
                          value={false}
                          checked={this.state.togglechecked === false || this.state.togglechecked === "false"}
                          onChange={this.handletoggleChange}
                          className="strategic-engagement-radio"
                        />
                        No
                      </label>
                    </div>
                  </div>

                  {this.state.togglechecked && (
                    <>
                      <div className={`input-label ${errors.togglechecked ? "required-label" : ""
                        }`} style={{ marginTop: "3rem" }} >
                        Which executive or speaker is best suited to support your
                        session objectives? *
                      </div>
                      <div className="speaker-involvement-note">Please note: submitting this request does not guarantee executive participation.</div>
                      <div className="searchContainer searchbarWidth">
                        <InputBase
                          className={"searchInput"}
                          placeholder="Search for a speaker / executive leader"
                          value={this.state.searchInput}
                          onChange={this.handleSearchChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon className="search-icon" />
                            </InputAdornment>
                          }
                        />
                      </div>
                      <div className="active-directory-user">
                        {this.state.searchInput !== "" &&
                          this.state.searchInput !== null &&
                          this.state.speakerData.map((item) => {
                            return (
                              <Card
                                className={`active-user-card ${this.state.selectedUserCard === item.mail
                                    ? "active-user-selected"
                                    : ""
                                  }`}
                                onClick={() => this.handleUserCardClick(item)}
                              >
                                <CardContent className="active-user-card-content">
                                  <div
                                    className={`active-user-card-title ${this.state.selectedUserCard === item.mail
                                        ? "selectedCard"
                                        : ""
                                      }`}
                                  >
                                    {item.displayName}
                                    <span
                                      className={`active-user-card-role ${this.state.selectedUserCard === item.mail
                                          ? "selectedCard"
                                          : ""
                                        }`}
                                    >
                                      {item.role}
                                    </span>
                                  </div>
                                  <div
                                    className={`active-user-card-description ${this.state.selectedUserCard === item.mail
                                        ? "selectedCard"
                                        : ""
                                      }`}
                                  >
                                    {item.mail}
                                  </div>
                                </CardContent>
                                <div className="active-user-icon-container">
                                  <DoneIcon className="active-user-icon" />
                                </div>
                              </Card>
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
              </Grid>
            )}
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: "10px",
            marginTop: `${toggle ? "1rem" : "11.5rem"}`,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            onClick={this.Previous}
          >
            previous
          </Button>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            onClick={this.Continue}
          >
            Next
          </Button>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withMsal
)(CreateForm);

