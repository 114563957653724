import "./App.css";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import config from './config/config';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./../src/flows/LandingPage/LandingPage";
import StrategicEngagement from "./../src/flows/StrategicEngagement/Main";
import TechnicalContentForm from "./../src/flows/TechincalContentRequest/Main";
import PgBuddyForm  from "./flows/PgBuddyProgram/Main";
import MicrosoftLogin2 from "./flows/Login/MLogin";
import FindSpeaker from "./flows/FindSpeaker/findSpeaker";

function App() {
  const msalConfig = {
    auth: {
      clientId: `${config.micorsoftClientId}`,
      authority: config.authorityUrl
    },
    cache: {
      cacheLocation: 'sessionStorage', // Cache location can be 'localStorage' or 'sessionStorage'
      storeAuthStateInCookie: false, // Set this to true if you want to store the auth state in a cookie
    },
  };
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
    <Router>
      <Routes>
        <Route exact path="/" element={<MicrosoftLogin2/>} />
        <Route exact path="/Home" element={<LandingPage />} />
        <Route exact path="/StrategicEngagement" element={<StrategicEngagement />}/>
        <Route exact path="/TechnicalContent" element={<TechnicalContentForm/>} />
        <Route exact path="/Pgbuddy" element={<PgBuddyForm />} />

        <Route exact path="/FindSpeaker" element={<FindSpeaker />} />
      </Routes>
    </Router>
    </MsalProvider>
  );
}

export default App;
