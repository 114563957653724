import React, { Component } from "react";
import withRouter from "./../../../components/withRouter";
import NavBar from "../../../components/NavBar/NavBar";
import { Save, Done } from "@mui/icons-material";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { Divider } from "@mui/material";
import "./index.css";
import { CreateForm } from "../Create";
import { DetailForm } from "../Details";
import Objective from "../Objective";
import ReviewForm from "../Review-Confirm";
import axios from "axios";
import config from "../../../config/config";
import successImg from "./../../../assets/success.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withMsal } from "@azure/msal-react";
import { compose } from "recompose";
import { Backdrop, CircularProgress } from "@mui/material";

const durationOptions = [
  { value: "30 minutes", label: "30 minutes", numericvalue: 30 },
  { value: "45 minutes", label: "45 minutes", numericvalue: 45 },
  { value: "1 hour", label: "1 hour", numericvalue: 60 },
  { value: "1 hr, 15 min", label: "1 hr, 15 min", numericvalue: 75 },
  { value: "1 hr, 30 min", label: "1 hr, 30 min", numericvalue: 90 },
  { value: "1 hr, 45 mins", label: "1 hr, 45 mins", numericvalue: 105 },
  { value: "2 hours", label: "2 hours", numericvalue: 120 },
];

const highDemandSpeakers = [
  "eric.boyd@microsoft.com",
  "johnmont@microsoft.com",
  "mcasalaina@microsoft.com",
  "Ali.Dalloul@microsoft.com",
  "Pablo.Castro@microsoft.com",
  "Gregory.Buehrer@microsoft.com",
  "bobbreynaert@microsoft.com",
  "Seth.Juarez@microsoft.com",
];

class Main extends Component {
  constructor(props) {
    super(props);
    const fields = this.props?.location?.state?.data?.fields;

    this.state = {
      step: 1,
      showLoader: false,
      requestProcessing: false,
      openmode: this.props?.location?.state?.action
        ? this.props?.location?.state?.action
        : "",
      user_id: sessionStorage.getItem("accountId"),
      user_name: sessionStorage.getItem("userName"),
      user_email: sessionStorage.getItem("Emailid"),
      request_type: "Strategic Customer Engagement or Event",
      engagement_type: fields
        ? fields["Custom.RequestEngagementType"]
        : "Customer Briefing (non-EBC)",
      customerName: fields ? fields["Custom.CustomersName"] : "",
      isPartner: fields ? fields["Custom.IsCustomerPartner"] : false,
      DigitalNative: fields ? fields["Custom.DigitalNative"] : false,
      S500Customer: fields ? fields["Custom.S500Customer"] : false,
      ExecutiveRequestFormCompleted: fields
        ? fields["Custom.ExecutiveRequestFormCompleted"]
        : false,
      teCoveredBySponser: fields
        ? fields["Custom.TEwillbecoveredbysponsor"]
        : false,
      executive_speaker_required: fields
        ? fields["Custom.IsExecutiveSpeakerRequired"]
        : false,
      executive_speaker_name: "",
      executive_speaker_id: "",
      executive_speaker_accountid: "",
      executive_speaker_workitem: "",
      engagementName: fields ? fields["System.Title"] : "",
      meetingFormat: fields ? fields["Custom.MeetingFormat"] : "",
      meetingLink: fields ? fields["Custom.MeetingsLink"] : "",
      location: fields ? fields["Custom.LocationField"] : "",
      requestedDate: fields ? fields["Custom.RequestedDateSpan"] : "",
      timeNote: fields ? fields["Custom.TimeNotes"] : "",
      fromDate: fields
        ? fields["Custom.FromDate"]?.length > 3
          ? fields["Custom.FromDate"].slice(0, -4)
          : null
        : null,
      toDate: fields
        ? fields["Custom.ToDate"]?.length > 3
          ? fields["Custom.ToDate"].slice(0, -4)
          : null
        : null,
      duration: fields
        ? fields["Custom.RequestTimeDuration"]
          ? durationOptions.find(
              (option) =>
                option.numericvalue ===
                parseInt(fields["Custom.RequestTimeDuration"])
            )?.label
          : null
        : null,
      opportunityId: fields ? fields["Custom.OpportunityID"] : "",
      notSalesOpportunity: fields
        ? fields["Custom.IsOpportunity"] === true
          ? false
          : true
        : false,
      customerAttendees: fields
        ? fields["Custom.AIAttendees_PL"]?.split(";")
        : [],
      showServiceInterest: fields
        ? fields["Custom.ServiceInterest"] &&
          fields["Custom.ServiceInterest"] !== ""
          ? true
          : false
        : false,
      serviceInterestOptions: [],
      technicalDepth: fields ? fields["Custom.TechnicalDepth"] : null,
      aiTopic: fields ? fields["Custom.AITopics"] : null,
      attachmentFile: null,
      serviceInterests: fields ? fields["Custom.ServiceInterest"] : "",
      engagementFocus: fields ? fields["Custom.EngagementFocus"] : null,
      customerObjectives: fields ? fields["Custom.CustomerObjectives"] : null,
      microsoftObjectives: fields ? fields["Custom.MicrosoftObjectives"] : null,
      attendee_bio: "",
      attachment: fields ? fields["Custom.CurrentAttachmentName"] : "",
      customTopicInterest: fields
        ? fields["Custom.AITopics"] === "Custom AI/ML topic"
          ? fields["Custom.CustomTopicTitle"]
          : ""
        : "",
      linkedInBio: fields ? fields["Custom.LinkedInBios"] : "",
      customTopicTitle: fields
        ? fields["Custom.CustomTopicTitle"] &&
          fields["Custom.CustomTopicTitle"] !== ""
          ? true
          : false
        : false,
      timeZone: fields ? fields["Custom.Timezone"] : "America/Los_Angeles",
      timeZoneLable: "",
      createdOn: fields ? fields["Custom.CreatedOn"] : "",
      timeHorizon: fields
        ? fields["Custom.Timehorizontoreachproduction"]
        : null,
      requestWorkitemId: this.props.location?.state?.data
        ? this.props.location?.state?.data["id"]
        : "",
      requestId: this.props.location?.state?.data
        ? this.props.location?.state?.data?.fields["Custom.RequestID"]
        : "",
      attendeeFetched: false,
      highDemandSpeakers: highDemandSpeakers,
      sixWeekWaiting: false,
      isSignedIn: false,
      changedSpeaker: false,
      isViewMode: this.props?.location?.state?.action === "view" ? true : false,
      industry: fields ? fields["Custom.CustomerIndustry"] : "",
      aiMaturityLevel: fields ? fields["Custom.AIMaturityLevel"] : "",
      appliedtoAOAI: fields ? fields["Custom.CustomerappliedtoAOAI"] : "",
      accesstoAOAI: fields ? fields["Custom.CustomerhasaccesstoAOAI"] : "",
    };
  }

  closeViewModePopup = () => {
    this.setState({
      isViewMode: false,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };
  openPopup = () => {
    this.setState({
      isPopupOpen: true,
    });
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    try {
      const { instance, accounts } = this.props.msalContext;
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: [
            "User.Read",
            "openid",
            "profile",
            "offline_access",
            "email",
            "User.ReadBasic.All",
          ],
          account: accounts ? accounts[0] : null,
        };
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        sessionStorage.setItem("userName", response.account.name);
        sessionStorage.setItem("Emailid", response.account.username);
        sessionStorage.setItem("accountId", response.account.localAccountId);
        sessionStorage.setItem("userToken", response.accessToken);
        this.setState({
          isSignedIn: true,
        });
      } else {
        throw Error("not found any current active account");
      }
    } catch (error) {
      console.log("Error acquiring token silently:", error);
      sessionStorage.clear();
      window.location.replace("/");
    }
  };

  closePopup = () => {
    this.setState({
      isPopupOpen: false,
    });
    const { navigate } = this.props;
    navigate("/Home");
  };

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  setParameters = (name, value) => {
    if (
      Array.isArray(name) &&
      (name[0] === "engagementFocus" ||
        name[0] === "customerObjectives" ||
        name[0] === "microsoftObjectives") || 
        name[0] === "meetingLink" ||
        name[0] === "location" ||
        name[0] === "timeNote" ||
        name[0] === "linkedInBio"
    ) {
      value = value?.replace(/<br>/g, '')?.replace(/<\/p><p>/g, '<br>')
    }
    this.setState({ [name]: value });
  };

  gotolandingPage = () => {
    const { navigate } = this.props;
    navigate("/Home");
  };

  create = async (event) => {
    if (this.state.requestProcessing === false) {
      this.setState({
        requestProcessing: true,
      });
      const formData = new FormData();
      if (this.state.user_name) {
        formData.append("user_name", this.state.user_name);
      } else {
        formData.append("user_name", "");
      }
      if (this.state.user_email) {
        formData.append("user_email", this.state.user_email);
      } else {
        formData.append("user_email", "");
      }
      if (this.state.request_type) {
        formData.append("request_type", this.state.request_type);
      } else {
        formData.append("request_type", "");
      }
      if (this.state.engagement_type) {
        formData.append("engagement_type", this.state.engagement_type);
      } else {
        formData.append("engagement_type", "");
      }
      if (this.state.customerName || this.state.customerName === "") {
        formData.append("customer_name", this.state.customerName);
      } else {
        formData.append("customer_name", "");
      }
      formData.append("is_customer_partner", this.state.isPartner);
      formData.append("DigitalNative", this.state.DigitalNative);
      formData.append("S500Customer", this.state.S500Customer);
      formData.append(
        "ExecutiveRequestFormCompleted",
        this.state.ExecutiveRequestFormCompleted
      );
      formData.append(
        "executive_speaker_required",
        this.state.executive_speaker_required
      );
      if (
        this.state.executive_speaker_accountid ||
        this.state.executive_speaker_accountid === ""
      ) {
        formData.append(
          "executive_speaker_id",
          this.state.executive_speaker_accountid
        );
      } else {
        formData.append("executive_speaker_id", "");
      }
      if (
        this.state.executive_speaker_name ||
        this.state.executive_speaker_name === ""
      ) {
        formData.append(
          "executive_speaker_name",
          this.state.executive_speaker_name
        );
      } else {
        formData.append("executive_speaker_name", "");
      }
      if (
        this.state.executive_speaker_id ||
        this.state.executive_speaker_id === ""
      ) {
        formData.append(
          "executive_speaker_email",
          this.state.executive_speaker_id
        );
      } else {
        formData.append("executive_speaker_email", "");
      }
      if (this.state.engagementName || this.state.engagementName === "") {
        formData.append("engagement_name", this.state.engagementName);
      } else {
        formData.append("engagement_name", "");
      }
      formData.append("meeting_format", this.state.meetingFormat);
      if (this.state.meetingLink || this.state.meetingLink === "") {
        formData.append("meeting_link", this.state.meetingLink);
      } else {
        formData.append("meeting_link", "");
      }
      if (this.state.location || this.state.location === "") {
        formData.append("location", this.state.location);
      } else {
        formData.append("location", "");
      }
      if (this.state.attachmentFile) {
        formData.append("attachment", this.state.attachmentFile);
      }
      formData.append("requested_date_span", this.state.requestedDate);
      if (this.state.timeNote || this.state.timeNote === "") {
        formData.append("time_notes", this.state.timeNote);
      } else {
        formData.append("time_notes", "");
      }
      if (this.state.fromDate) {
        formData.append("from_date", this.state.fromDate);
      } else {
        formData.append("from_date", "");
      }
      if (this.state.toDate) {
        formData.append("to_date", this.state.toDate);
      } else {
        formData.append("to_date", "");
      }
      if (this.state.duration) {
        const duarationobj = durationOptions.find(
          (option) => option.label === this.state.duration
        );
        if (duarationobj) {
          formData.append("duration", duarationobj.numericvalue);
          formData.append("duration_text", this.state.duration);
        } else {
          formData.append("duration", "");
          formData.append("duration_text", "");
        }
      } else {
        formData.append("duration", "");
        formData.append("duration_text", "");
      }
      if (this.state.opportunityId || this.state.opportunityId === "") {
        formData.append("opportunity_id", this.state.opportunityId);
      } else {
        formData.append("opportunity_id", "");
      }
      if (this.state.industry || this.state.industry === "") {
        formData.append("industry", this.state.industry);
      } else {
        formData.append("industry", "");
      }
      if (this.state.aiMaturityLevel || this.state.aiMaturityLevel === "") {
        formData.append("aiMaturityLevel", this.state.aiMaturityLevel);
      } else {
        formData.append("aiMaturityLevel", "");
      }
      if (this.state.appliedtoAOAI || this.state.appliedtoAOAI === "") {
        formData.append("appliedOpenAIService", this.state.appliedtoAOAI);
      } else {
        formData.append("appliedOpenAIService", "");
      }
      if (this.state.accesstoAOAI || this.state.accesstoAOAI === "") {
        formData.append("receivedOpenAIService", this.state.accesstoAOAI);
      } else {
        formData.append("receivedOpenAIService", "");
      }
      if (this.state.aiTopic !== "Custom AI/ML topic") {
        formData.append("custom_topic_title", this.state.aiTopic || "");
      } else {
        formData.append(
          "custom_topic_title",
          this.state.customTopicInterest || ""
        );
      }
      formData.append("technical_depth", this.state.technicalDepth);
      formData.append("ai_topic", this.state.aiTopic);
      if (this.state.serviceInterests || this.state.serviceInterests === "") {
        formData.append("service_interest", this.state.serviceInterests);
      } else {
        formData.append("service_interest", "");
      }
      if (this.state.timeHorizon || this.state.timeHorizon === "") {
        formData.append("time_horizon", this.state.timeHorizon);
      } else {
        formData.append("time_horizon", "");
      }

      formData.append("te_covered_sponsor", this.state.teCoveredBySponser);
      formData.append(
        "not_sales_opportunities",
        !this.state.notSalesOpportunity
      );
      formData.append("customer_attendees", this.state.customerAttendees);
      if (this.state.timeZone) {
        formData.append("time_zone", this.state.timeZone);
        formData.append("time_zone_label", this.state.timeZoneLable);
      } else {
        formData.append("time_zone", "");
        formData.append("time_zone_label", "");
      }
      if (this.state.engagementFocus || this.state.engagementFocus === "") {
        formData.append("engagement_focus", this.state.engagementFocus);
      } else {
        formData.append("engagement_focus", "");
      }
      if (
        this.state.customerObjectives ||
        this.state.customerObjectives === ""
      ) {
        formData.append("customer_objectives", this.state.customerObjectives);
      } else {
        formData.append("customer_objectives", "");
      }
      if (
        this.state.microsoftObjectives ||
        this.state.microsoftObjectives === ""
      ) {
        formData.append("microsoft_objectives", this.state.microsoftObjectives);
      } else {
        formData.append("microsoft_objectives", "");
      }
      if (this.state.linkedInBio || this.state.linkedInBio === "") {
        formData.append("attendee_bio", this.state.linkedInBio);
      } else {
        formData.append("attendee_bio", "");
      }

      try {
        this.setState({
          showLoader: true,
        });
        const url =
          `${config.apiBaseUrl}/request/engagementConference/` +
          sessionStorage.getItem("accountId");
        var response;
        if (this.state.openmode === "edit") {
          if (this.state.requestId) {
            formData.append("request_id", this.state.requestId);
          }
          if (this.state.requestWorkitemId) {
            formData.append("workitemid", this.state.requestWorkitemId);
          }
          if (
            this.state.executive_speaker_workitem &&
            this.state.executive_speaker_workitem !== ""
          ) {
            formData.append(
              "executive_speaker_workitem",
              this.state.executive_speaker_workitem
            );
          }
          response = await axios.put(url, formData, {
            headers: {
              "Content-Type": undefined,
            },
          });
        } else {
          response = await axios.post(url, formData, {
            headers: {
              "Content-Type": undefined,
            },
          });
        }
        this.openPopup();
        this.setState({
          requestId: response.data.data?.fields["Custom.RequestID"],
          showLoader: false,
        });
      } catch (error) {
        this.setState({
          showLoader: false,
        });
        toast.error("Failed to submit request!", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      this.setState({
        requestProcessing: false,
      });
    }
  };

  render() {
    const { step } = this.state;
    return (
      <>
        {this.state.isSignedIn && (
          <div className="bodyContainer">
            <NavBar />
            <div className="strategic-title">
              Strategic Customer Engagement or Event
            </div>
            <div className="pageContainer">
              <div className="wizardBody">
                <div className="contentPage">
                  <div className="wizardTab">
                    <div
                      className={`modelCircle ${
                        step === 1 || step === 2 || step === 3 || step === 4
                          ? "activeCircle"
                          : ""
                      }`}
                    >
                      <Save />
                    </div>
                    <Divider
                      color={
                        step === 2 || step === 3 || step === 4
                          ? "#1FA2FF"
                          : "#E5E5E5"
                      }
                      style={{ width: "34%", height: ".3rem" }}
                    />
                    <div
                      className={`modelCircle ${
                        step === 2 || step === 3 || step === 4
                          ? "activeCircle"
                          : ""
                      }`}
                    >
                      <TextSnippetOutlinedIcon />
                    </div>
                    <Divider
                      color={step === 3 || step === 4 ? "#1FA2FF" : "#E5E5E5"}
                      style={{ width: "34%", height: ".3rem" }}
                    />
                    <div
                      className={`modelCircle ${
                        step === 3 || step === 4 ? "activeCircle" : ""
                      }`}
                    >
                      <WidgetsOutlinedIcon />
                    </div>
                    <Divider
                      color={step === 4 ? "#1FA2FF" : "#E5E5E5"}
                      style={{ width: "34%", height: ".3rem" }}
                    />
                    <div
                      className={`modelCircle ${
                        step === 4 ? "activeCircle" : ""
                      }`}
                    >
                      <Done />
                    </div>
                  </div>
                  <div
                    className="wizardTab transparent-div"
                    style={{ marginBottom: "1rem", paddingTop: "15px" }}
                  >
                    <div className="stepone">
                      <div className="step-label">Step1-</div>
                      <h4 className="topic-heading">Create</h4>
                    </div>
                    <div style={{ width: "100%" }} />
                    <div className="steptwo">
                      <div className="step-label">Step2-</div>
                      <h4 className="topic-heading">Details</h4>
                    </div>
                    <div style={{ width: "100%" }} />
                    <div className="stepthree">
                      <div className="step-label">Step3-</div>
                      <h4 className="topic-heading">Objectives</h4>
                    </div>
                    <div style={{ width: "100%" }} />
                    <div className="stepfour">
                      <div className="step-label">Step4-</div>
                      <h4 className="topic-heading">Review&Confirm</h4>
                    </div>
                  </div>
                  {step === 1 && (
                    <CreateForm
                      nextStep={this.nextStep}
                      setParameters={this.setParameters}
                      values={this.state}
                      msalContext={this.props.msalContext}
                      previous={this.gotolandingPage}
                      workitemData={
                        this.props?.location?.state?.action === "edit" ||
                        this.props?.location?.state?.action === "view"
                          ? this.props?.location?.state
                          : null
                      }
                    />
                  )}
                  {step === 2 && (
                    <DetailForm
                      prevStep={this.prevStep}
                      nextStep={this.nextStep}
                      msalContext={this.props.msalContext}
                      setParameters={this.setParameters}
                      values={this.state}
                    />
                  )}
                  {step === 3 && (
                    <Objective
                      prevStep={this.prevStep}
                      nextStep={this.nextStep}
                      msalContext={this.props.msalContext}
                      setParameters={this.setParameters}
                      values={this.state}
                    />
                  )}
                  {step === 4 && (
                    <ReviewForm
                      prevStep={this.prevStep}
                      create={this.create}
                      values={this.state}
                      msalContext={this.props.msalContext}
                    />
                  )}
                </div>
              </div>
            </div>
            {this.state.isPopupOpen && (
              <div className="popup-overlay">
                <div className="popup">
                  <div className="popup-content">
                    <div className="popup-image">
                      <img src={successImg} alt="Popup" />
                    </div>
                    {this.state.openmode === "" && (
                      <div className="popup-text">
                        <p>
                          This request was successfully submitted! Your Engage
                          AI Request Id is:
                          <span className="request-id">
                            {` ${this.state.requestId}.`}{" "}
                          </span>
                        </p>
                        <p>
                          A confirmation email will be sent to you. With
                          generous lead time ( {">"} 14) and adequate guidance,
                          we source speakers and executives with a +95% success
                          rate. We will connect you with a speaker once they
                          have accepted; however, you can always inquire
                          EngageAI@microsoft.com regarding the status of your
                          request.
                        </p>
                      </div>
                    )}
                    {this.state.openmode === "edit" && (
                      <div className="popup-text">
                        <p>
                          Your request{" "}
                          <span className="request-id">
                            {` ${this.state.requestId}`}{" "}
                          </span>{" "}
                          was successfully updated.
                        </p>
                      </div>
                    )}
                    <br />
                    <button className="popupSubmit" onClick={this.closePopup}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}
            {this.state.isViewMode && (
              <div className="ebc-popup-overlay">
                <div className="ebc-popup">
                  <div className="ebc-popup-content">
                    <p className="custom-line-height">
                      Your request has moved past 'speaker requested' status.
                      Hence, it can only be opened in READONLY mode. If you want
                      to make changes to this request, please reach out to{" "}
                      <a
                        href="mailto:engageai@microsoft.com"
                        title="mailto:engageai@microsoft.com"
                      >
                        engageai@microsoft.com
                      </a>
                      .
                    </p>
                    <br />
                    <button
                      className="popupSubmit"
                      onClick={this.closeViewModePopup}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}
            <ToastContainer />
          </div>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.showLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

export default compose(withRouter, withMsal)(Main);
