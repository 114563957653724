import React from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Checkbox,
} from "@mui/material";
import "./index.css";
import withRouter from "./../../../components/withRouter";
import NavBar from "../../../components/NavBar/NavBar";
import { Divider } from "@mui/material";
import { InputBase, IconButton } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@material-ui/icons/Close";
import successImg from "./../../../assets/success.png";
import Select from "react-select";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config/config";
import ReactQuill from "react-quill";
import { Grid } from "@material-ui/core";
import { withMsal } from "@azure/msal-react";
import { compose } from "recompose";
import { Backdrop, CircularProgress } from "@mui/material";

const FileDropzone = ({ onDrop, children }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`dropzone pgBuddyDropZone ${isDragActive && "active"}`}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

const timeHorizonValues = [
  "1-3 months",
  "4-6 months",
  "7-9 months",
  "10-12 months",
  "12+ months",
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "45px",
    height: "45px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "45px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "45px",
  }),
};

class PgBuddyForm extends React.Component {
  constructor(props) {
    super(props);
    const fields = this.props?.location?.state?.data?.fields;
    this.state = {
      showLoader: false,
      searchInput: fields
        ? fields["Custom.OpportunityIDforEngagementRequests"]
        : "",
      user_id: sessionStorage.getItem("accountId"),
      user_name: sessionStorage.getItem("userName"),
      user_email: sessionStorage.getItem("Emailid"),
      openmode: this.props?.location?.state?.action
        ? this.props?.location?.state?.action
        : "",
      IsGBBAccount: fields ? fields["Custom.IsGBBWorking"] : null,
      program: fields ? fields["Custom.Program"] : "AzureML",
      employees: fields
        ? fields["Custom.CurrentEmployees"] &&
          fields["Custom.CurrentEmployees"] !== ""
          ? fields["Custom.CurrentEmployees"]?.split(";")
          : []
        : [],
      timeHorizon: fields
        ? {
            value: fields["Custom.Timehorizontoreachproduction"],
            label: fields["Custom.Timehorizontoreachproduction"],
          }
        : "",
      requiredHelp: fields
        ? fields["Custom.Helpunabletoprovide"]
            ?.replace(/<p><br><\/p>/g, "<br>")
            ?.replace(/<br><p>/g, "<p>")
            ?.replace(/<p><br> <\/p>/g, "")
        : "",
      attachment: fields ? fields["Custom.CurrentAttachmentName"] : "",
      IsPartnerInvolved: fields ? fields["Custom.IsPartnerInvolved"] : null,
      wellKnownBrand: fields ? fields["Custom.Iscustomerknownbrand"] : null,
      HaveExecutiveSupport: fields
        ? fields["Custom.Customerhaveinternalsupport"]
        : null,
      CustomerEvidence: fields
        ? fields["Custom.IsWillingtobeapartofcustomerevidence"]
        : "",
      CustomerUseCase: fields
        ? fields["Custom.CustomerUseCaseDescription"]
            ?.replace(/<p><br><\/p>/g, "<br>")
            ?.replace(/<br><p>/g, "<p>")
            ?.replace(/<p><br> <\/p>/g, "")
        : "",
      attachmentFile: null,
      partnerTitle: fields ? fields["Custom.ThePartnerTitle"] : "",
      Request_id: this.props.location?.state?.data
        ? this.props.location?.state?.data.fields["Custom.RequestID"]
        : "",
      requestWorkitemId: this.props.location?.state?.data
        ? this.props.location?.state?.data["id"]
        : "",
      errors: {},
      isPopupOpen: false,
      isopportunityPopupOpen: false,
      isSignedIn: false,
      isViewMode: this.props?.location?.state?.action === "view" ? true : false,
      requestProcessing: false,
    };
  }

  openPopup = () => {
    this.setState({
      isPopupOpen: true,
    });
  };

  closeViewModePopup = () => {
    this.setState({
      isViewMode: false,
    });
  };

  handleOppenOpprutnityPopup = () => {
    this.setState({
      isopportunityPopupOpen: true,
    });
  };

  closeOpportunityPopup = () => {
    this.setState({
      isopportunityPopupOpen: false,
    });
  };

  onEditorStateChange = (label, editorState) => {
    if (editorState.replace(/<[^>]+>/g, "")?.trim() === "") {
      editorState = "";
    }
    if (document.getElementById(label)?.innerText?.length <= 2000) {
      this.setState({
        [label]: editorState,
      });
    } else {
      this.setState({
        [label]: this.state[label],
      });
    }
  };
  closePopup = () => {
    this.setState({
      isPopupOpen: false,
    });
    const { navigate } = this.props;
    navigate("/Home");
  };
  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log("target values : ", event.target);
    if (type === "checkbox") {
      const { employees } = this.state;
      if (checked) {
        this.setState({ employees: [...employees, name] });
      } else {
        this.setState({ employees: employees.filter((emp) => emp !== name) });
      }
    } else {
      if (name === "IsPartnerInvolved") {
        this.setState({ partnerTitle: "" });
      }
      this.setState({ [name]: value });
    }
  };

  searchOpportunities = () => {};

  handleFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({
      attachment: file?.name ? file.name : "",
      attachmentFile: file,
    });
  };
  handleDropdownChange = (name, option) => {
    this.setState({ [name]: option });
  };

  handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    this.setState({
      attachment: file?.name ? file.name : "",
      attachmentFile: file,
    });
  };

  handletimeZoneSelect = (timezone) => {
    this.setState({
      timeZone: timezone,
    });
  };

  Previous = () => {
    const { navigate } = this.props;
    navigate("/Home");
  };

  handleSubmit = async (event) => {
    if (this.state.requestProcessing === false) {
      this.setState({
        requestProcessing: true,
      });
      event.preventDefault();
      const errors = {};
      if (
        this.state.searchInput === null ||
        (typeof this.state.searchInput === "string" &&
          this.state.searchInput?.trim() === "")
      ) {
        errors.searchInput = true;
      }
      if (
        this.state.IsGBBAccount == null ||
        (typeof this.state.IsGBBAccount === "string" &&
          this.state.IsGBBAccount?.trim() === "")
      ) {
        errors.IsGBBAccount = true;
      }
      if (
        this.state.IsPartnerInvolved === null ||
        (typeof this.state.IsPartnerInvolved === "string" &&
          this.state.IsPartnerInvolved?.trim() === "")
      ) {
        errors.IsPartnerInvolved = true;
      }
      if (
        this.state.IsPartnerInvolved === "true" &&
        (this.state.partnerTitle === null ||
          (typeof this.state.partnerTitle === "string" &&
            this.state.partnerTitle?.trim() === ""))
      ) {
        errors.partnerTitle = true;
      }
      if (
        this.state.HaveExecutiveSupport === null ||
        (typeof this.state.HaveExecutiveSupport === "string" &&
          this.state.HaveExecutiveSupport?.trim() === "")
      ) {
        errors.HaveExecutiveSupport = true;
      }
      if (
        this.state.CustomerEvidence === null ||
        (typeof this.state.CustomerEvidence === "string" &&
          this.state.CustomerEvidence?.trim() === "")
      ) {
        errors.CustomerEvidence = true;
      }
      if (
        this.state.timeHorizon === null ||
        (typeof this.state.timeHorizon === "string" &&
          this.state.timeHorizon?.trim() === "")
      ) {
        errors.timeHorizon = true;
      }
      if (
        this.state.requiredHelp === null ||
        (typeof this.state.requiredHelp === "string" &&
          this.state.requiredHelp?.trim() === "")
      ) {
        errors.requiredHelp = true;
      }
      if (
        this.state.CustomerUseCase === null ||
        (typeof this.state.CustomerUseCase === "string" &&
          this.state.CustomerUseCase?.trim() === "")
      ) {
        errors.CustomerUseCase = true;
      }
      if (Object.keys(errors).length === 0) {
        // All fields are valid, proceed to the next page
        // You can navigate or perform any necessary action here

        this.setState({ errors: {} });
        const formData = new FormData();
        formData.append("request_type", "AI Platform Buddy Program");
        // formData.append('engagement_type', 'PG Buddy Program');
        if (this.state.user_name) {
          formData.append("user_name", this.state.user_name);
        }
        if (this.state.user_email) {
          formData.append("user_email", this.state.user_email);
        }
        formData.append("program", "AzureML");
        if (this.state.searchInput || this.state.searchInput === "") {
          formData.append("opportunity_id", this.state.searchInput);
        } else {
          formData.append("opportunity_id", "");
        }
        // formData.append('opportunity_id', null);
        formData.append("is_gbb_working", this.state.IsGBBAccount);
        formData.append("is_partner_involved", this.state.IsPartnerInvolved);
        if (this.state.partnerTitle || this.state.partnerTitle === "") {
          formData.append("partner_title", this.state.partnerTitle);
        } else {
          formData.append("partner_title", "");
        }
        formData.append("employees_customer", this.state.employees);
        if (this.state.wellKnownBrand !== null) {
          formData.append("customer_known_brand", this.state.wellKnownBrand);
        }
        formData.append(
          "have_internal_support",
          this.state.HaveExecutiveSupport
        );
        formData.append("attachment", this.state.attachmentFile); // 'file' should be a File object
        formData.append(
          "willing_part_customer_evidence",
          this.state.CustomerEvidence
        );
        formData.append(
          "time_horizon",
          this.state.timeHorizon ? this.state.timeHorizon.value : null
        );
        if (this.state.requiredHelp || this.state.requiredHelp === "") {
          formData.append("required_help_description", this.state.requiredHelp);
        } else {
          formData.append("required_help_description", "");
        }
        if (this.state.CustomerUseCase || this.state.CustomerUseCase === "") {
          formData.append("customer_use_case", this.state.CustomerUseCase);
        } else {
          formData.append("customer_use_case", "");
        }
        try {
          this.setState({
            showLoader: true,
          });
          var response;
          const url =
            `${config.apiBaseUrl}/request/pgbuddy/` +
            sessionStorage.getItem("accountId");
          if (this.state.openmode === "edit") {
            if (this.state.Request_id) {
              formData.append("request_id", this.state.Request_id);
            }
            if (this.state.requestWorkitemId) {
              formData.append("workitemid", this.state.requestWorkitemId);
            }
            response = await axios.put(url, formData, {
              headers: {
                "Content-Type": undefined,
              },
            });
          } else {
            response = await axios.post(url, formData, {
              headers: {
                "Content-Type": undefined,
              },
            });
          }
          this.setState({
            Request_id: response.data.data?.fields["Custom.RequestID"],
            showLoader: false,
          });
          this.openPopup();
        } catch (error) {
          toast.error("Failed to submit request!", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({
            showLoader: false,
          });
        }
      } else {
        this.setState({ errors });
        toast.warn("Please fill the required fields highlighted into red!", {
          position: toast.POSITION.TOP_CENTER,
          toastId: 2,
        });
      }
      this.setState({
        requestProcessing: false,
      });
    }
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    try {
      const { instance, accounts } = this.props.msalContext;
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: [
            "User.Read",
            "openid",
            "profile",
            "offline_access",
            "email",
            "User.ReadBasic.All",
          ],
          account: accounts ? accounts[0] : null,
        };
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        sessionStorage.setItem("userName", response.account.name);
        sessionStorage.setItem("Emailid", response.account.username);
        sessionStorage.setItem("accountId", response.account.localAccountId);
        sessionStorage.setItem("userToken", response.accessToken);
        this.setState({
          isSignedIn: true,
        });
      } else {
        throw Error("not found any current active account");
      }
    } catch (error) {
      console.log("Error acquiring token silently:", error);
      sessionStorage.clear();
      window.location.replace("/");
    }
  };

  render() {
    const { errors, employees } = this.state;

    return (
      <>
        {this.state.isSignedIn && (
          <div className="PgFormContainer">
            <NavBar></NavBar>
            <div className="pgform-title">
              AzureML AI Platform Buddy Program Request
            </div>
            <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
              <Divider></Divider>
            </div>
            <form className="form">
              <fieldset
                disabled={this.state.openmode === "view" ? "disabled" : ""}
              >
                <Grid
                  container
                  spacing={0}
                  style={{ paddingLeft: "5%", paddingRight: "5%" }}
                >
                  <div className="skip-note" style={{ marginTop: "15px" }}>
                    <span className="bolder">Note:</span> Please feel free to
                    skip fields that do not apply to your request.
                  </div>
                  <Grid item xs={12} sm={12}>
                    <div
                      className="label-container"
                      style={{ marginBottom: "-5px", marginTop: "30px" }}
                    >
                      <label
                        htmlFor="searchInput"
                        className={`input-label ${
                          errors.searchInput ? "required-label" : ""
                        }`}
                      >
                        Opportunity Id(s) *
                        <span
                          className="more-info-link"
                          onClick={this.handleOppenOpprutnityPopup}
                        >
                          (click here for more info)
                        </span>
                      </label>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="searchBar">
                      <div className="pgSearchContainer">
                        <InputBase
                          id="searchInput"
                          name="searchInput"
                          className={"searchInput"}
                          placeholder="Enter Opportunity Id(s)"
                          value={this.state.searchInput}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field">
                      <div className="label-container">
                        <label
                          htmlFor="IsGBBAccount"
                          className={`input-label ${
                            errors.IsGBBAccount ? "required-label" : ""
                          }`}
                        >
                          Is there a GBB working with this account? *
                        </label>
                      </div>
                      <RadioGroup
                        id="IsGBBAccount"
                        name="IsGBBAccount"
                        value={this.state.IsGBBAccount}
                        onChange={this.handleChange}
                        error={errors.IsGBBAccount}
                      >
                        <div>
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                            className="radio-button-label"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </div>
                      </RadioGroup>
                      {errors.IsGBBAccount && (
                        <span className="error-message">
                          {errors.IsGBBAccount}
                        </span>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field">
                      <div className="radio-button-label-containear">
                        <label
                          htmlFor="IsPartnerInvolved"
                          className={`input-label ${
                            errors.IsPartnerInvolved ? "required-label" : ""
                          }`}
                        >
                          Is a partner involved? *
                        </label>
                      </div>
                      <RadioGroup
                        id="IsPartnerInvolved"
                        name="IsPartnerInvolved"
                        value={this.state.IsPartnerInvolved}
                        onChange={this.handleChange}
                        error={!!errors.IsPartnerInvolved}
                      >
                        <div>
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                            className="radio-button-label"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </div>
                      </RadioGroup>
                      {errors.IsPartnerInvolved && (
                        <span className="error-message">
                          {errors.IsPartnerInvolved}
                        </span>
                      )}
                    </div>
                  </Grid>

                  {(this.state.IsPartnerInvolved === "true" ||
                    this.state.IsPartnerInvolved === true) && (
                    <Grid item xs={12} sm={12}>
                      <div className="input-field-with-help-text">
                        <div className="label-container">
                          <label
                            htmlFor="partnerTitle"
                            className={`input-label ${
                              errors.partnerTitle ? "required-label" : ""
                            }`}
                          >
                            Partner Title *
                          </label>
                        </div>
                        <TextField
                          id="partnerTitle"
                          name="partnerTitle"
                          placeholder="Enter Partner Title"
                          value={this.state.partnerTitle}
                          onChange={this.handleChange}
                          inputProps={{ maxLength: 100 }}
                          helperText={
                            this.state.partnerTitle !== null &&
                            this.state.partnerTitle?.length > 0
                              ? `${
                                  100 - this.state.partnerTitle?.length
                                } characters remaining`
                              : "100 characters remaining"
                          }
                          fullWidth
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <div className="input-field">
                      <div className="radio-button-label-containear">
                        <label htmlFor="employees" className="input-label">
                          Which of the following employees does the customer
                          currently have?
                        </label>
                      </div>
                      <div className="checkbox-group">
                        {/* Checkbox Group */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Data Scientists"
                              checked={employees?.includes("Data Scientists")}
                              onChange={this.handleChange}
                            />
                          }
                          label="Data Scientists"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Citizen Data Scientists"
                              checked={employees?.includes(
                                "Citizen Data Scientists"
                              )}
                              onChange={this.handleChange}
                            />
                          }
                          label="Citizen Data Scientists"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Data Engineering"
                              checked={employees?.includes("Data Engineering")}
                              onChange={this.handleChange}
                            />
                          }
                          label="Data Engineering"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="ML Engineering"
                              checked={employees?.includes("ML Engineering")}
                              onChange={this.handleChange}
                            />
                          }
                          label="ML Engineering"
                        />
                      </div>

                      <div
                        className="input-field"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="radio-button-label-containear">
                          <label
                            htmlFor="wellKnownBrand"
                            className="input-label"
                          >
                            Is the customer a well known brand or a leader in
                            their industry?
                          </label>
                        </div>
                        <RadioGroup
                          id="wellKnownBrand"
                          name="wellKnownBrand"
                          value={this.state.wellKnownBrand}
                          onChange={this.handleChange}
                          error={!!errors.wellKnownBrand}
                        >
                          <div>
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Yes"
                              className="radio-button-label"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="No"
                            />
                          </div>
                        </RadioGroup>
                        {errors.wellKnownBrand && (
                          <span className="error-message">
                            {errors.wellKnownBrand}
                          </span>
                        )}
                      </div>

                      <div className="input-field">
                        <div className="radio-button-label-containear">
                          <label
                            htmlFor="HaveExecutiveSupport"
                            className={`input-label ${
                              errors.HaveExecutiveSupport
                                ? "required-label"
                                : ""
                            }`}
                          >
                            Does the customer have internal executive
                            support/sponsorship for this initiative? *
                          </label>
                        </div>
                        <RadioGroup
                          id="HaveExecutiveSupport"
                          name="HaveExecutiveSupport"
                          value={this.state.HaveExecutiveSupport}
                          onChange={this.handleChange}
                          error={errors.HaveExecutiveSupport}
                        >
                          <div>
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Yes"
                              className="radio-button-label"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="No"
                            />
                          </div>
                        </RadioGroup>
                        {errors.HaveExecutiveSupport && (
                          <span className="error-message">
                            {errors.HaveExecutiveSupport}
                          </span>
                        )}
                      </div>

                      <div className="input-field">
                        <div className="radio-button-label-containear">
                          <label
                            htmlFor="CustomerEvidence"
                            className={`input-label ${
                              errors.CustomerEvidence ? "required-label" : ""
                            }`}
                          >
                            Is the customer willing to be a part of customer
                            evidence (success story, PR, blog etc.), if they
                            choose AML as their data science platform of choice?
                            *
                          </label>
                        </div>
                        <RadioGroup
                          id="CustomerEvidence"
                          name="CustomerEvidence"
                          value={this.state.CustomerEvidence}
                          onChange={this.handleChange}
                          error={errors.CustomerEvidence}
                        >
                          <div>
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                              className="radio-button-label"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                            <FormControlLabel
                              value="Unsure"
                              control={<Radio />}
                              label="Unsure"
                            />
                          </div>
                        </RadioGroup>
                        {errors.HaveExecutiveSupport && (
                          <span className="error-message">
                            {errors.HaveExecutiveSupport}
                          </span>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field">
                      <div className="label-container">
                        <label
                          htmlFor="timeHorizon"
                          className={`input-label ${
                            errors.timeHorizon ? "required-label" : ""
                          }`}
                        >
                          What is expected time horizon for this workload to
                          reach production *
                        </label>
                      </div>
                      <Select
                        id="timeHorizon"
                        name="timeHorizon"
                        styles={customStyles}
                        options={timeHorizonValues.map((option) => ({
                          value: option,
                          label: option,
                        }))}
                        value={this.state.timeHorizon}
                        onChange={(selectedOption) =>
                          this.handleDropdownChange(
                            "timeHorizon",
                            selectedOption
                          )
                        }
                        error={errors.timeHorizon}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field-with-help-text">
                      <div className="label-container">
                        <label
                          htmlFor="requiredHelp"
                          className={`input-label ${
                            errors.requiredHelp ? "required-label" : ""
                          }`}
                        >
                          What help does the customer require that a GBB/CSA is
                          unable to provide? *
                        </label>
                      </div>

                      <div className="richtext-containear-pgbuddy">
                        <ReactQuill
                          id="requiredHelp"
                          theme="snow"
                          value={this.state.requiredHelp}
                          onChange={(editorState) => {
                            this.onEditorStateChange(
                              "requiredHelp",
                              editorState
                            );
                          }}
                          readOnly={
                            this.state.openmode === "view" ? true : false
                          }
                        />
                        <div className="helper-text-containear">
                          <div className="helper-text">
                            {this.state.requiredHelp === ""
                              ? "2000 characters remaining"
                              : `${
                                  2000 -
                                  document.getElementById("requiredHelp")
                                    ?.innerText?.length
                                } characters remaining`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field-with-help-text">
                      <div className="label-container">
                        <label
                          htmlFor="CustomerUseCase"
                          className={`input-label ${
                            errors.CustomerUseCase ? "required-label" : ""
                          }`}
                        >
                          Describe the customer's use case. What does the
                          customer want to use Azure Machine Learning for?
                          (Predictive maintenance, Computer vision, etc.) *
                        </label>
                      </div>
                      <div className="richtext-containear-pgbuddy">
                        <ReactQuill
                          id="CustomerUseCase"
                          theme="snow"
                          value={this.state.CustomerUseCase}
                          onChange={(editorState) => {
                            this.onEditorStateChange(
                              "CustomerUseCase",
                              editorState
                            );
                          }}
                          readOnly={
                            this.state.openmode === "view" ? true : false
                          }
                        />
                        <div className="helper-text-containear">
                          <div className="helper-text">
                            {this.state.requiredHelp === ""
                              ? "2000 characters remaining"
                              : `${
                                  2000 -
                                  document.getElementById("CustomerUseCase")
                                    ?.innerText?.length
                                } characters remaining`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div
                      className={`input-field ${
                        this.state.openmode === "view"
                          ? "poniter-event-none"
                          : ""
                      }`}
                    >
                      <div className="label-container">
                        <label htmlFor="attachment" className="input-label">
                          (Optional) Attachments
                        </label>
                      </div>
                      <div className="drag-drop-file-upload">
                        <FileDropzone
                          onDrop={
                            this.state.openmode !== "view"
                              ? this.handleDrop
                              : undefined
                          }
                          className="pgBuddyDropZone"
                        >
                          <input
                            type="text"
                            value={this.state.attachment}
                            placeholder="Drag and Drop Attachment"
                            readOnly
                            className="file-name"
                          />
                        </FileDropzone>
                        <label
                          className={`upload-btn ${
                            this.state.openmode === "view"
                              ? "disable-upload"
                              : ""
                          }`}
                        >
                          Upload
                          <input
                            type="file"
                            onChange={this.handleFileChange}
                            style={{ display: "none" }}
                          />
                        </label>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                {/* Buttons */}
              </fieldset>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12}>
                  <div className="pgBuddySubmit-buttons-container">
                    <Button
                      variant="outlined"
                      id="previous"
                      onClick={this.Previous}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      id="submit"
                      color="primary"
                      onClick={this.handleSubmit}
                      disabled={
                        this.state.openmode === "view" ? "disabled" : ""
                      }
                    >
                      {this.state.openmode === "edit" ? "update" : "Submit"}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
            {this.state.isPopupOpen && (
              <div className="popup-overlay">
                <div className="popup">
                  <div className="popup-content">
                    <div className="popup-image">
                      <img src={successImg} alt="Popup" />
                    </div>
                    {this.state.openmode === "" && (
                      <div className="popup-text">
                        <p>
                          This request was successfully submitted! Your Engage
                          AI Request Id is:
                          <span className="request-id">
                            {` ${this.state.Request_id}.`}{" "}
                          </span>
                        </p>
                      </div>
                    )}
                    {this.state.openmode === "edit" && (
                      <div className="popup-text">
                        <p>
                          Your request{" "}
                          <span className="request-id">{` ${this.state.Request_id}`}</span>{" "}
                          was successfully updated.
                        </p>
                      </div>
                    )}
                    <br />
                    <button className="popupSubmit" onClick={this.closePopup}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}
            {this.state.isopportunityPopupOpen && (
              <div className="opportunity-popup-overlay">
                <div className="opportunity-popup">
                  <div className="opportunity-popup-header">
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={this.closeOpportunityPopup}
                      style={{ marginRight: "10px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div className="opportunity-popup-content">
                    <div className="opportunity-popup-text">
                      <p>
                        Please enter Opportunity ID in this field. If there are
                        multiple Opportunity IDs, then, please enter them as
                        comma separated values e.g. 7-36GQU6NW6I, D1M0-000480,
                        6-85HCU6NW88
                      </p>
                      <p>
                        If you have an opportunity ID, it helps our team triage
                        your request when we have additional information. If you
                        don't have an opportunity ID handy, please click this
                        link to retrieve it:{" "}
                        <a
                          href="https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=custom&name=msp_msxhomepage_e01cc"
                          target="_blank"
                          rel="noreferrer"
                        >
                          MSX Home Page - Dynamics 365
                        </a>
                      </p>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            )}
            {this.state.isViewMode && (
              <div className="ebc-popup-overlay">
                <div className="ebc-popup">
                  <div className="ebc-popup-content">
                    <p className="custom-line-height">
                      Your request has moved past 'speaker requested' status.
                      Hence, it can only be opened in READONLY mode. If you want
                      to make changes to this request, please reach out to{" "}
                      <a
                        href="mailto:engageai@microsoft.com"
                        title="mailto:engageai@microsoft.com"
                      >
                        engageai@microsoft.com
                      </a>
                      .
                    </p>
                    <br />
                    <button
                      className="popupSubmit"
                      onClick={this.closeViewModePopup}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}
            <ToastContainer />
          </div>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.showLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

export default compose(withRouter, withMsal)(PgBuddyForm);
