import React, { Component } from 'react';
import ErrorFallback from './exceptionhandled'; // Your error fallback component

class GlobalErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error or perform other actions here
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorFallback />;
        }
        try {
            return this.props.children;
        } catch (error) {
            console.error(error);
            return <ErrorFallback />;
        }
    }
}

export default GlobalErrorBoundary;
