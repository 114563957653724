import React, { useState } from "react";
import "./NavBar.css";
import MicrosoftIcon from "./../../assets/MicrosoftIcon.png";
import { Divider } from "@mui/material";


export default function NavBar({ children }) {
  const userName = sessionStorage.getItem("userName");
  const emailId = sessionStorage.getItem("Emailid");

  const initials = userName
    .split(" ")
    .map((word) => word.charAt(0))
    .slice(0, 2)
    .join("");

  const [isSignoutPopupOpen, setSignoutPopupOpen] = useState(false);

  const handleLogout = () => {
    // setSignoutPopupOpen(!isSignoutPopupOpen);
  };

  const handleSignoutlink = () => {
    sessionStorage.clear();
    window.location.replace('/');
  };
  return (
    <div className="navbar">
      <img src={MicrosoftIcon} alt="Company Logo" className="logo" />
      <text
        style={{
          fontSize: "20px",
          color: "black",
          marginLeft: "10px",
          fontWeight: "bold",
          fontFamily: "Segoe UI Regular",
        }}
      >
        Microsoft{" "}
      </text>

      <Divider orientation="vertical" flexItem style={{ margin: "10px 10px" }} />

      <text
        style={{
          fontSize: "15px",
          color: "#2088ff",
          fontWeight: "bold",
          fontFamily: "Segoe UI Regular",
          marginTop: "3px",
        }}
      >
        AI Engagement Requests
      </text>
      <div className="spacer" />
      <div className="user-info">
        <div>
          <div className="user-name">{userName}</div>
          <div className="user-role">{emailId}</div>
        </div>
      </div>
      <div className="avatar" onClick={handleLogout}>
        {initials}
      </div>
      {isSignoutPopupOpen && (
        <div className="signout-popup">
          <div className="signout-popup-content">
            <div className="user-info">
              <div>
                <div className="user-name">{userName}</div>
                <div className="user-role">{emailId}</div>
              </div>
            </div>
            <Divider/>
            <div className="signout-link" onClick={handleSignoutlink}>
              Sign Out
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
