import React from "react";
import {
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import "./index.css";
import withRouter from "./../../../components/withRouter";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@material-ui/core";
import { withMsal } from "@azure/msal-react";
import { compose } from "recompose";

const aiTopicList = [
  {
    label: "Azure Machine Learning Platform",
    serviceInterest: true,
    serviceOptions: ["Azure Machine Learning", "Responsible AI with Azure"],
    customTopicTitle: false,
  },
  {
    label: "Azure AI Services",
    serviceInterest: true,
    serviceOptions: [
      "Azure AI Services",
      "Azure AI Bot Service",
      "Azure AI Decision",
      "Azure AI Document Intelligence",
      "Azure AI Immersive Reader",
      "Azure AI Language",
      "Azure AI Metrics Advisor",
      "Azure AI Speech",
      "Azure AI Video Indexer",
      "Azure AI Vision",
      "Azure Cognitive Search",
      "Azure OpenAI Service",
    ],
    customTopicTitle: false,
  },
  {
    label: "Custom AI/ML topic",
    serviceInterest: false,
    serviceOptions: [],
    customTopicTitle: true,
  },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "45px",
    height: "45px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "45px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "45px",
  }),
};

const FileDropzone = ({ onDrop, children }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive && "active"}`}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

class Objective extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openmode: this.props.values.openmode,
      ExecutiveRequestFormCompleted:
        this.props.values.ExecutiveRequestFormCompleted,
      opportunityId: this.props.values.opportunityId
        ? this.props.values.opportunityId
        : "",
      customerAttendees:
        this.props.values.customerAttendees &&
        this.props.values.customerAttendees.length > 0
          ? this.props.values.customerAttendees.map((value) => {
              return { value: value, label: value };
            })
          : [],
      technicalDepth: this.props.values.technicalDepth
        ? {
            value: this.props.values.technicalDepth,
            label: this.props.values.technicalDepth,
          }
        : null,
      serviceInterests: this.props.values.serviceInterests
        ? {
            value: this.props.values.serviceInterests,
            label: this.props.values.serviceInterests,
          }
        : null,
      engagementFocus: this.props.values.engagementFocus
        ? this.props.values.engagementFocus
        : "",
      timeHorizon: this.props.values.timeHorizon
        ? {
            value: this.props.values.timeHorizon,
            label: this.props.values.timeHorizon,
          }
        : "",
      microsoftObjectives: this.props.values.microsoftObjectives
        ? this.props.values.microsoftObjectives
        : "",
      notSalesOpportunity: this.props.values.notSalesOpportunity
        ? this.props.values.notSalesOpportunity
        : false,
      attachment: this.props.values.attachment
        ? this.props.values.attachment
        : "",
      attachmentFile: this.props.values.attachmentFile
        ? this.props.values.attachmentFile
        : null,
      aiTopic: this.props.values.aiTopic
        ? aiTopicList.find((item) => item.label === this.props.values.aiTopic)
        : null,
      aiTopicLabel: this.props.values.aiTopic
        ? this.props.values.aiTopic
        : null,
      customerObjectives: this.props.values.customerObjectives
        ? this.props.values.customerObjectives
        : "",
      errors: {},
      showServiceInterest: this.props.values.showServiceInterest
        ? this.props.values.showServiceInterest
        : false,
      serviceInterestOptions: this.props.values.aiTopic
        ? aiTopicList.find((item) => item.label === this.props.values.aiTopic)
            ?.serviceOptions
        : null,
      customTopicTitle: this.props.values.customTopicTitle
        ? this.props.values.aiTopic !== "Custom AI/ML topic"
          ? false
          : true
        : false,
      customTopic: this.props.values.customTopic
        ? this.props.values.customTopic
        : null,
      linkedInBio: this.props.values.linkedInBio
        ? this.props.values.linkedInBio
        : "",
      isPopupOpen: false,
      customTopicInterest: this.props.values.customTopicInterest
        ? this.props.values.customTopicInterest
        : "",
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.props.setParameters([name], value);
  };

  handleOppenOpprutnityPopup = () => {
    this.setState({
      isPopupOpen: true,
    });
  };

  closeOpportunityPopup = () => {
    this.setState({
      isPopupOpen: false,
    });
  };

  handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
    if (name === "notSalesOpportunity") {
      this.setState({
        opportunityId: "",
      });
      this.props.setParameters("opportunityId", "");
    }
    this.props.setParameters([name], checked);
  };

  handleDropdownChange = (name, option) => {
    this.setState({ [name]: option });
    this.props.setParameters([name], option.value);
  };

  handleAttendiesChange = (name, options) => {
    const selectedValues = options.map((option) => option.value);
    this.setState({ [name]: options });
    this.props.setParameters([name], selectedValues);
  };

  handleAITopicChange = (event) => {
    this.setState({
      serviceInterests: null,
    });
    this.setState({
      aiTopicLabel: event.label,
      aiTopic: event.option,
      showServiceInterest: event.serviceInterest,
      serviceInterestOptions: event.serviceInterestsOptions,
      customTopicTitle: event.customTopicTitle,
    });
    this.props.setParameters("aiTopic", event.label);
    this.props.setParameters("showServiceInterest", event.serviceInterest);
    this.props.setParameters(
      "serviceInterestOptions",
      event.serviceInterestsOptions
    );
    this.props.setParameters("customTopicTitle", event.customTopicTitle);
    if (!event.serviceInterest) {
      this.setState({
        serviceInterests: null,
      });
      this.props.setParameters("serviceInterests", null);
    }
    if (!event.customTopicTitle) {
      this.props.setParameters("customTopicInterest", null);
    }
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({
      attachment: file?.name ? file.name : "",
      attachmentFile: file,
    });
    this.props.setParameters("attachment", file.name);
    this.props.setParameters("attachmentFile", file);
  };

  handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    this.setState({
      attachment: file?.name ? file.name : "",
      attachmentFile: file,
    });
    this.props.setParameters("attachment", file.name);
    this.props.setParameters("attachmentFile", file);
  };

  Previous = () => {
    const { prevStep } = this.props;
    prevStep();
  };
  onEditorStateChange = (label, editorState) => {
    if (editorState.replace(/<[^>]+>/g, "")?.trim() === "") {
      editorState = "";
    }
    this.setState({
      [label]: editorState,
    });
    this.props.setParameters([label], editorState);
  };

  handleSubmit = (event) => {
    const { nextStep } = this.props;
    event.preventDefault();
    const errors = {};
    if (
      this.state.notSalesOpportunity === false &&
      this.state.opportunityId.trim() === ""
    ) {
      errors.opportunityId = "Opportunity ID is required";
    }

    if (this.state.customerAttendees.length === 0) {
      errors.customerAttendees = "Customer Attendees is required";
    }

    if (
      !(
        this.state.technicalDepth &&
        this.state.technicalDepth.constructor === Object &&
        Object.keys(this.state.technicalDepth).length !== 0
      )
    ) {
      errors.technicalDepth = "technical Depth is required";
    }

    if (
      this.state.engagementFocus.trim() === "" ||
      this.state.engagementFocus.replace(/<[^>]+>/g, "")?.trim() === ""
    ) {
      errors.engagementFocus = "Engagement Focus is required";
    }

    if (this.state.microsoftObjectives.trim() === "") {
      errors.microsoftObjectives = "Microsoft Objectives is required";
    }

    if (!this.state.aiTopicLabel) {
      errors.aiTopic = "AI Topic is required";
    }

    if (
      this.state.aiTopicLabel === "Custom AI/ML topic" &&
      this.state.customTopicInterest?.trim() === ""
    ) {
      errors.customTopicInterest = "Customer Topic interset is required";
    }

    if (this.state.customerObjectives.trim() === "") {
      errors.customerObjectives = "Customer Objectives is required";
    }
    if (Object.keys(errors).length === 0) {
      this.setState({ errors: {} });
      nextStep();
    } else {
      this.setState({ errors });
      toast.warn("Please fill the required fields highlighted into red!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    var successfulSignIn = false;
    try {
      const { instance, accounts } = this.props.msalContext;
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: [
            "User.Read",
            "openid",
            "profile",
            "offline_access",
            "email",
            "User.ReadBasic.All",
          ],
          account: accounts ? accounts[0] : null,
        };
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        sessionStorage.setItem("userName", response.account.name);
        sessionStorage.setItem("Emailid", response.account.username);
        sessionStorage.setItem("accountId", response.account.localAccountId);
        sessionStorage.setItem("userToken", response.accessToken);
        this.setState({
          isSignedIn: true,
        });
        successfulSignIn = true;
      } else {
        throw Error("not found any current active account");
      }
    } catch (error) {
      console.log("Error acquiring token silently:", error);
      sessionStorage.clear();
      window.location.replace("/");
    }
    if (successfulSignIn) {
      this.setState({
        engagementFocus: this.state.engagementFocus?.includes("<p><br></p>") || this.state.engagementFocus?.includes("</p><p>")
          ? this.state.engagementFocus?.replace(/<br>/g, '')?.replace(/<\/p><p>/g, '<br>')
          : this.state.engagementFocus,
        microsoftObjectives: this.state.microsoftObjectives?.includes("<p><br></p>") || this.state.microsoftObjectives?.includes("</p><p>")
          ? this.state.microsoftObjectives?.replace(/<br>/g, '')?.replace(/<\/p><p>/g, '<br>')
          : this.state.microsoftObjectives,
        customerObjectives: this.state.customerObjectives?.includes("<p><br></p>") || this.state.customerObjectives?.includes("</p><p>")
          ? this.state.customerObjectives?.replace(/<br>/g, '')?.replace(/<\/p><p>/g, '<br>')
          : this.state.customerObjectives,
        linkedInBio: this.state.linkedInBio?.includes("<p><br></p>") || this.state.linkedInBio?.includes("</p><p>")
          ? this.state.linkedInBio?.replace(/<br>/g, '')?.replace(/<\/p><p>/g, '<br>')
          : this.state.linkedInBio,
      });
    }
  };

  render() {
    const { errors } = this.state;
    const customerAttendeesOptions = [
      "Business Decision Makers",
      "Technology Decision Makers",
      "Developer Decision Makers",
      "Developers/Data Scientists",
      "IT Professionals",
      "Business Roles",
    ];

    const aiTopicOptions = [
      {
        label: "Azure Machine Learning Platform",
        serviceInterest: true,
        serviceOptions: ["Azure Machine Learning", "Responsible AI with Azure"],
        customTopicTitle: false,
      },
      {
        label: "Azure AI Services",
        serviceInterest: true,
        serviceOptions: [
          "Azure AI Services",
          "Azure AI Bot Service",
          "Azure AI Decision",
          "Azure AI Document Intelligence",
          "Azure AI Immersive Reader",
          "Azure AI Language",
          "Azure AI Metrics Advisor",
          "Azure AI Speech",
          "Azure AI Video Indexer",
          "Azure AI Vision",
          "Azure Cognitive Search",
          "Azure OpenAI Service",
        ],
        customTopicTitle: false,
      },
      {
        label: "Custom AI/ML topic",
        serviceInterest: false,
        serviceOptions: [],
        customTopicTitle: true,
      },
    ];

    const serviceInterestsOptions = this.state.serviceInterestOptions;

    const technicalDepth = [
      "L100-Foundational",
      "L200-Aspiring",
      "L300-Applied",
      "L400-Mature",
    ];
    const timeHorizonValues = [
      "1-3 months",
      "4-6 months",
      "7-9 months",
      "10-12 months",
      "12+ months",
      "N/A",
    ];

    return (
      <form onSubmit={this.handleSubmit} className="form">
        <fieldset disabled={this.state.openmode === "view" ? "disabled" : ""}>
          <Grid
            container
            spacing={0}
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
          >
            <div
              className="skip-note"
              style={{ paddingBottom: "30px", marginTop: "-15px" }}
            >
              <span className="bolder">Note:</span> Please feel free to skip
              fields that do not apply to your request.
            </div>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container">
                  <label
                    htmlFor="opportunityId"
                    className={`input-label ${
                      errors.opportunityId ? "required-label" : ""
                    }`}
                  >
                    {`Opportunity Id(s) ${
                      this.state.notSalesOpportunity ? "" : "*"
                    }`}
                    <span
                      className="more-info-link"
                      onClick={this.handleOppenOpprutnityPopup}
                    >
                      (click here for more info)
                    </span>
                  </label>
                </div>
                <TextField
                  id="opportunityId"
                  name="opportunityId"
                  placeholder="Enter opportunity Id(s)"
                  value={this.state.opportunityId}
                  onChange={this.handleChange}
                  disabled={this.state.notSalesOpportunity}
                  fullWidth
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.notSalesOpportunity}
                      value={this.state.notSalesOpportunity}
                      onChange={this.handleCheckBoxChange}
                    />
                  }
                  label="Not a Sales Opportunity "
                  id="notSalesOpportunity"
                  name="notSalesOpportunity"
                  error={!!errors.notSalesOpportunity}
                />
                {errors.notSalesOpportunity && (
                  <span className="error-message">
                    {errors.notSalesOpportunity}
                  </span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div
                className={`input-field ${
                  this.state.openmode === "view" ? "poniter-event-none" : ""
                }`}
              >
                <div className="label-container">
                  <label htmlFor="attachment" className="input-label">
                    (Optional) Attachment (e.g., briefing doc, bio or other):
                  </label>
                </div>
                <div className="drag-drop-file-upload">
                  <FileDropzone onDrop={this.handleDrop}>
                    <input
                      type="text"
                      value={this.state.attachment}
                      placeholder="Drag and Drop Attachment"
                      readOnly
                      className="file-name"
                    />
                  </FileDropzone>
                  <label
                    className={`upload-btn ${
                      this.state.openmode === "view" ? "disable-upload" : ""
                    }`}
                  >
                    Upload
                    <input
                      type="file"
                      onChange={this.handleFileChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ExecutiveRequestFormCompleted"
                      value={this.state.ExecutiveRequestFormCompleted}
                      checked={this.state.ExecutiveRequestFormCompleted}
                      onChange={this.handleCheckBoxChange}
                    />
                  }
                  label={
                    <div className={`input-label`}>
                      Executive Request Form Completed? (Required if an
                      Executive is requested) -
                      <a
                        href="https://aka.ms/airequest-executive"
                        className={`required-link`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click Here to fill the form
                      </a>
                    </div>
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container">
                  <label
                    htmlFor="linkedInBio"
                    className={`input-label ${
                      errors.linkedInBio ? "required-label" : ""
                    }`}
                  >
                    LinkedIn Bios
                  </label>
                </div>
                {/* <TextField
                                    id="linkedInBio"
                                    name="linkedInBio"
                                    placeholder="Enter LinkedIn Bios"
                                    value={this.state.linkedInBio}
                                    onChange={this.handleChange}
                                    fullWidth
                                /> */}
                <div className="richtext-containear">
                  <ReactQuill
                    id="linkedInBio"
                    theme="snow"
                    placeholder="Enter LinkedIn Bios"
                    value={this.state.linkedInBio}
                    onChange={(editorState) => {
                      this.onEditorStateChange("linkedInBio", editorState);
                    }}
                    disabled={this.state.openmode === "view" ? "disabled" : ""}
                    readOnly={this.state.openmode === "view" ? true : false}
                  />
                  {/* <div className='helper-text-containear'><div className='helper-text'>{this.state.customerObjectives === '' ? '10000 characters remaining' : `${10000 - document.getElementById("customerObjectives")?.innerText?.length} characters remaining`}</div></div> */}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div
                className={`input-field ${
                  this.state.openmode === "view" ? "disabled" : ""
                }`}
              >
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="customerAttendees"
                    className={`input-label ${
                      errors.customerAttendees ? "required-label" : ""
                    }`}
                  >
                    Customer Attendees *
                    <Tooltip
                      className="tooltip-background"
                      title="Select the roles expected to attend from the customer?"
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <Select
                  id="customerAttendees"
                  name="customerAttendees"
                  options={customerAttendeesOptions.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  isMulti
                  value={this.state.customerAttendees}
                  styles={customStyles}
                  onChange={(selectedOptions) =>
                    this.handleAttendiesChange(
                      "customerAttendees",
                      selectedOptions
                    )
                  }
                  classNamePrefix="react-select"
                  isDisabled={this.state.openmode === "view" ? true : false}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="technicalDepth"
                    className={`input-label ${
                      errors.technicalDepth ? "required-label" : ""
                    }`}
                  >
                    Technical depth *
                    <Tooltip
                      className="tooltip-background"
                      title="How technical do the attendees expect the discussion and content?"
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <Select
                  id="technicalDepth"
                  name="technicalDepth"
                  options={technicalDepth.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  styles={customStyles}
                  value={this.state.technicalDepth}
                  onChange={(selectedOption) =>
                    this.handleDropdownChange("technicalDepth", selectedOption)
                  }
                  // error={!!errors.technicalDepth}
                  classNamePrefix="react-select"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="aiTopic"
                    className={`input-label ${
                      errors.aiTopic ? "required-label" : ""
                    }`}
                  >
                    AI Topic *
                    <Tooltip
                      className="tooltip-background"
                      title="Select the topic you wish to cover"
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <Select
                  id="aiTopic"
                  name="aiTopic"
                  options={aiTopicOptions.map((option) => ({
                    value: option.label,
                    label: option.label,
                    serviceInterest: option.serviceInterest,
                    serviceInterestsOptions: option.serviceOptions,
                    customTopicTitle: option.customTopicTitle,
                  }))}
                  value={this.state.aiTopic}
                  styles={customStyles}
                  onChange={this.handleAITopicChange}
                  // error={!!errors.aiTopic}
                  classNamePrefix="react-select"
                />
              </div>
            </Grid>
            {this.state.showServiceInterest && (
              <Grid item xs={12} sm={12}>
                <div className="input-field">
                  <div className="label-container-with-info-icon">
                    <label htmlFor="serviceInterests" className="input-label">
                      Service Interest
                      <Tooltip
                        className="tooltip-background"
                        title="[Optional] Select a service. If none selected, we assume a platform overview is expected"
                      >
                        <IconButton
                          className="tooltip-icon-button"
                          aria-label="info"
                        >
                          <InfoIcon className="tooltip-icon" />
                        </IconButton>
                      </Tooltip>
                    </label>
                  </div>
                  <Select
                    id="serviceInterests"
                    name="serviceInterests"
                    styles={customStyles}
                    options={serviceInterestsOptions.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    value={this.state.serviceInterests}
                    onChange={(selectedOption) =>
                      this.handleDropdownChange(
                        "serviceInterests",
                        selectedOption
                      )
                    }
                    error={!!errors.serviceInterests}
                    classNamePrefix="react-select"
                  />
                </div>
              </Grid>
            )}

            {this.state.customTopicTitle && (
              <Grid item xs={12} sm={12}>
                <div className="input-field">
                  <div className="label-container-with-info-icon">
                    <label
                      htmlFor="customTopicInterest"
                      className={`input-label ${
                        errors.customTopicInterest ? "required-label" : ""
                      }`}
                    >
                      Custom Topic Title *
                      <Tooltip
                        className="tooltip-background"
                        title="NOTE: The subject matter expertise as well as the technical depth required can greatly affect a speaker's willingness to accept an engagement request. There is no guarantee the request will be fulfilled."
                      >
                        <IconButton
                          className="tooltip-icon-button"
                          aria-label="info"
                        >
                          <InfoIcon className="tooltip-icon" />
                        </IconButton>
                      </Tooltip>
                    </label>
                  </div>
                  <TextField
                    id="customTopicInterest"
                    name="customTopicInterest"
                    placeholder="Enter Custom Topic Interest"
                    value={this.state.customTopicInterest}
                    onChange={this.handleChange}
                    // error={!!errors.engagementFocus}
                    // helperText={errors.engagementFocus}
                    fullWidth
                  />
                </div>
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div
                  classfName="label-container"
                  style={{ marginBottom: "15px" }}
                >
                  <label
                    htmlFor="timeHorizon"
                    className={`input-label ${
                      errors.timeHorizon ? "required-label" : ""
                    }`}
                  >
                    What is expected time horizon for this workload to reach
                    production?
                  </label>
                </div>
                <Select
                  id="timeHorizon"
                  name="timeHorizon"
                  styles={customStyles}
                  options={timeHorizonValues.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  value={this.state.timeHorizon}
                  onChange={(selectedOption) =>
                    this.handleDropdownChange("timeHorizon", selectedOption)
                  }
                  // error={!!errors.timeHorizon}
                  classNamePrefix="react-select"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <Grid item xs={12} sm={12}>
                  <div className="label-container-with-info-icon">
                    <label
                      htmlFor="engagementFocus"
                      className={`input-label ${
                        errors.engagementFocus ? "required-label" : ""
                      }`}
                    >
                      Engagement Focus *
                      <Tooltip
                        className="tooltip-background"
                        title="Explain the discussion areas, including use cases or scenarios to cover"
                      >
                        <IconButton
                          className="tooltip-icon-button"
                          aria-label="info"
                        >
                          <InfoIcon className="tooltip-icon" />
                        </IconButton>
                      </Tooltip>
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className="richtext-containear">
                    <ReactQuill
                      id="engagementFocus"
                      theme="snow"
                      placeholder="Enter Engagement Focus"
                      value={this.state.engagementFocus}
                      onChange={(editorState) => {
                        this.onEditorStateChange(
                          "engagementFocus",
                          editorState
                        );
                      }}
                      disabled={this.state.openmode === "view"}
                      className="react-quill"
                      readOnly={this.state.openmode === "view" ? true : false}
                    />
                  </div>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="microsoftObjectives"
                    className={`input-label ${
                      errors.microsoftObjectives ? "required-label" : ""
                    }`}
                  >
                    Microsoft Objectives *
                    <Tooltip
                      className="tooltip-background"
                      title="What specifically is the customer looking to achieve from this engagement?"
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <div className="richtext-containear">
                  <ReactQuill
                    id="microsoftObjectives"
                    theme="snow"
                    placeholder="Enter Microsoft Objectives"
                    value={this.state.microsoftObjectives}
                    onChange={(editorState) => {
                      this.onEditorStateChange(
                        "microsoftObjectives",
                        editorState
                      );
                    }}
                    disabled={this.state.openmode === "view" ? "disabled" : ""}
                    readOnly={this.state.openmode === "view" ? true : false}
                  />
                  {/* <div className='helper-text-containear'><div className='helper-text'>{this.state.microsoftObjectives === '' ? '10000 characters remaining' : `${10000 - document.getElementById("microsoftObjectives")?.innerText?.length} characters remaining`}</div></div> */}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="input-field">
                <div className="label-container-with-info-icon">
                  <label
                    htmlFor="customerObjectives"
                    className={`input-label ${
                      errors.customerObjectives ? "required-label" : ""
                    }`}
                  >
                    Customer Objectives *
                    <Tooltip
                      className="tooltip-background"
                      title="What are the specific Microsoft Objectives for this engagement?"
                    >
                      <IconButton
                        className="tooltip-icon-button"
                        aria-label="info"
                      >
                        <InfoIcon className="tooltip-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </div>
                <div className="richtext-containear">
                  <ReactQuill
                    id="customerObjectives"
                    placeholder="Enter Customer Objectives"
                    theme="snow"
                    value={this.state.customerObjectives}
                    onChange={(editorState) => {
                      this.onEditorStateChange(
                        "customerObjectives",
                        editorState
                      );
                    }}
                    disabled={this.state.openmode === "view" ? "disabled" : ""}
                    readOnly={this.state.openmode === "view" ? true : false}
                  />
                  {/* <div className='helper-text-containear'><div className='helper-text'>{this.state.customerObjectives === '' ? '10000 characters remaining' : `${10000 - document.getElementById("customerObjectives")?.innerText?.length} characters remaining`}</div></div> */}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
          </Grid>
        </fieldset>
        {/* Buttons */}
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
            <div className="objective-buttons-container">
              <Button variant="outlined" onClick={this.Previous}>
                Previous
              </Button>
              <Button variant="outlined" color="primary" type="submit">
                Next
              </Button>
            </div>
          </Grid>
        </Grid>
        {this.state.isPopupOpen && (
          <div className="opportunity-popup-overlay">
            <div className="opportunity-popup">
              <div className="opportunity-popup-header">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={this.closeOpportunityPopup}
                  style={{ marginRight: "10px" }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="opportunity-popup-content">
                <div className="opportunity-popup-text">
                  <p>
                    Please enter Opportunity ID in this field. If there are
                    multiple Opportunity IDs, then, please enter them as comma
                    separated values e.g. 7-36GQU6NW6I, D1M0-000480,
                    6-85HCU6NW88
                  </p>
                  <p>
                    If you have an opportunity ID, it helps our team triage your
                    request when we have additional information. If you don't
                    have an opportunity ID handy, please click this link to
                    retrieve it:{" "}
                    <a
                      href="https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=custom&name=msp_msxhomepage_e01cc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MSX Home Page - Dynamics 365
                    </a>
                  </p>
                </div>
                <br />
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </form>
    );
  }
}

export default compose(withRouter, withMsal)(Objective);
