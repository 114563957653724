import React, { Component } from "react";
import "./index.css";
import withRouter from "./../../../components/withRouter";
import { withMsal } from "@azure/msal-react";
import { compose } from "recompose";
import NavBar from "../../../components/NavBar/NavBar";
import { Divider } from "@mui/material";
import { Grid } from "@material-ui/core";
import Select from "react-select";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TimezoneSelect from "react-timezone-select";
import successImg from "./../../../assets/success.png";
import { toast, ToastContainer } from "react-toastify";
import config from "../../../config/config";
import { useDropzone } from "react-dropzone";
import { Backdrop, CircularProgress } from "@mui/material";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
} from "@mui/material";
const moment = require("moment");
const contentAssetsTypeOptions = [
  { label: "AI Show", value: "AI Show" },
  { label: "Demo", value: "Demo" },
  {
    label: "AI YouTube Channel Video Submission",
    value: "AI YouTube Channel Video Submission",
  },
  { label: "Blog Post", value: "Blog Post" },
  { label: "Field Briefing", value: "Field Briefing" },
  { label: "Technical Consult", value: "Technical Consult" },
  { label: "Skilling Session", value: "Skilling Session" },
  { label: "Technical Documentation", value: "Technical Documentation" },
  { label: "Technical Workshop", value: "Technical Workshop" },
];

const FileDropzone = ({ onDrop, children }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`dropzone pgBuddyDropZone ${isDragActive && "active"}`}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

class TechnicalContentForm extends Component {
  constructor(props) {
    super(props);
    const fields = this.props?.location?.state?.data?.fields;
    this.state = {
      isSignedIn: false,
      errors: {},
      user_name: sessionStorage.getItem("userName"),
      user_email: sessionStorage.getItem("Emailid"),
      showLoader: false,
      requestProcessing: false,
      openmode: this.props?.location?.state?.action
        ? this.props?.location?.state?.action
        : "",
      contentAssetsType: fields
        ? contentAssetsTypeOptions.find(
            (item) => item.label === fields["Custom.ContentAssetType"]
          )
        : null,
      title: fields ? fields["Custom.ContentTitle"] || "" : "",
      businessArea: fields ? fields["Custom.BusinessArea"] || "" : "",
      focusedProductSearvice: fields
        ? fields["Custom.ProductOrServiceName"] || ""
        : "",
      contentOwnerName: fields
        ? fields["Custom.NameOfContentOwner"]
          ? [
              {
                value:
                  fields["Custom.NameOfContentOwner"] +
                  " | " +
                  fields["Custom.ContentOwnerEmail"],
                displayName: fields["Custom.NameOfContentOwner"] || "",
                mail: fields["Custom.ContentOwnerEmail"] || "",
                localId: fields["Custom.ContentOwnerID"] || "",
                label: fields["Custom.NameOfContentOwner"],
              },
            ]
          : []
        : [],
      contentOwnerNameOptions: [],
      microsoftUsersList: [],
      descriptionSummary: fields
        ? fields["Custom.DescriptionOrExecutiveSummary"]
          ? fields["Custom.DescriptionOrExecutiveSummary"]
              ?.replace(/<p><br><\/p>/g, "<br>")
              ?.replace(/<br><p>/g, "<p>")
          : ""
        : "",
      businessUseCase: fields
        ? fields["Custom.BusinessOrUseCase"]
          ? fields["Custom.BusinessOrUseCase"]
              ?.replace(/<p><br><\/p>/g, "<br>")
              ?.replace(/<br><p>/g, "<p>")
          : ""
        : "",
      desiredOutcome: fields
        ? fields["Custom.DesiredOutcome"]
          ? fields["Custom.DesiredOutcome"]
              ?.replace(/<p><br><\/p>/g, "<br>")
              ?.replace(/<br><p>/g, "<p>")
          : ""
        : "",
      isCampaignOrProductLaunch: fields
        ? fields["Custom.MarketingCampaignOrUpcomingLaunch"]
        : null,
      assignedPMM: fields
        ? fields["Custom.PPMAssignedToName"]
          ? [
              {
                value:
                  fields["Custom.PPMAssignedToName"] +
                  " | " +
                  fields["Custom.PPMAssignedToEmail"],
                displayName: fields["Custom.PPMAssignedToName"] || "",
                mail: fields["Custom.PPMAssignedToEmail"] || "",
                localId: fields["Custom.PPMAssignedToID"] || "",
                label: fields["Custom.PPMAssignedToName"],
              },
            ]
          : []
        : [],
      assignedPMMOptions: [],
      presenter: fields
        ? fields["Custom.MicrosoftPresenterName"]
          ? [
              {
                value:
                  fields["Custom.MicrosoftPresenterName"] +
                  " | " +
                  fields["Custom.MicrosoftPresenterEmail"],
                displayName: fields["Custom.MicrosoftPresenterName"] || "",
                mail: fields["Custom.MicrosoftPresenterEmail"] || "",
                localId: fields["Custom.MicrosoftPresenterID"] || "",
                label: fields["Custom.MicrosoftPresenterName"],
              },
            ]
          : []
        : [],
      presenterOptions: [],
      nonMicrosoftPresenter: fields
        ? fields["Custom.NameOfNonMicrosoftPresenter"] || ""
        : "",
      prsenterLocation: fields ? fields["Custom.PresenterLocation"] || "" : "",
      timeZone: fields
        ? fields["Custom.PresenterTimeZone"] || "America/Los_Angeles"
        : "America/Los_Angeles",
      publicationDate: fields
        ? fields["Custom.PublicationDate"]?.length > 3
          ? moment.utc(fields["Custom.PublicationDate"]).format("YYYY-MM-DD")
          : ""
        : "",
      expirationDate: fields
        ? fields["Custom.ExpirationDate"]?.length > 3
          ? moment.utc(fields["Custom.ExpirationDate"]).format("YYYY-MM-DD")
          : ""
        : "",
      sessionLength: fields ? fields["Custom.SegmentLength"] || "" : "",
      metaDataKeyword: fields ? fields["Custom.MetadataKeywords"] || "" : "",
      targetAudience: fields ? fields["Custom.TypeOfAudience"] || "" : "",
      allContentLinks: fields
        ? fields["Custom.ResourceLinks"]
          ? fields["Custom.ResourceLinks"]
              ?.replace(/<p><br><\/p>/g, "<br>")
              ?.replace(/<br><p>/g, "<p>")
          : ""
        : "",
      additionalInfo: fields
        ? fields["Custom.MiscNotes"]
          ? fields["Custom.MiscNotes"]
              ?.replace(/<p><br><\/p>/g, "<br>")
              ?.replace(/<br><p>/g, "<p>")
          : ""
        : "",
      requestWorkitemId: this.props.location?.state?.data
        ? this.props.location?.state?.data["id"]
        : "",
      requestId: this.props.location?.state?.data
        ? this.props.location?.state?.data?.fields["Custom.RequestID"]
        : "",
      minDate: fields
        ? fields["Custom.CreatedOn"]
          ? moment(moment.utc(fields["Custom.CreatedOn"]))
              .add(1, "weeks")
              .format("YYYY-MM-DD")
          : moment(moment()).add(1, "weeks").format("YYYY-MM-DD")
        : moment(moment()).add(1, "weeks").format("YYYY-MM-DD"),
      authorOrpresenterShortBio: fields
        ? fields["Custom.AuthorOrpresenterShortBio"]
          ? fields["Custom.AuthorOrpresenterShortBio"]
              ?.replace(/<p><br><\/p>/g, "<br>")
              ?.replace(/<br><p>/g, "<p>")
          : ""
        : "",
      authorOrpresenterSocialLinks: fields
        ? fields["Custom.AuthorOrpresenterSocialLinks"]
          ? fields["Custom.AuthorOrpresenterSocialLinks"]
              ?.replace(/<p><br><\/p>/g, "<br>")
              ?.replace(/<br><p>/g, "<p>")
          : ""
        : "",
      minPublicationDateError: false,
      timeZoneLabel: "",
      maxExpiryDateError: false,
      isViewMode: this.props?.location?.state?.action === "view" ? true : false,
      authorOrPresenterHeadshotAttachment: fields
        ? fields["Custom.AuthorOrPresenterHeadshotAttachmentName"]
        : "",
      authorOrPresenterHeadshotAttachmentFile: null,
      additionalDocsOrGraphicsAttachment: fields
        ? fields["Custom.AdditionalDocsOrGraphicsAttachmentName"]
        : "",
      additionalDocsOrGraphicsAttachmentFile: null,
    };
    this.handleUserChange = this.handleUserChange.bind(this);
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    var successfulSignIn = false;
    try {
      const { instance, accounts } = this.props.msalContext;
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: [
            "User.Read",
            "openid",
            "profile",
            "offline_access",
            "email",
            "User.ReadBasic.All",
          ],
          account: accounts ? accounts[0] : null,
        };
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        sessionStorage.setItem("userName", response.account.name);
        sessionStorage.setItem("Emailid", response.account.username);
        sessionStorage.setItem("accountId", response.account.localAccountId);
        sessionStorage.setItem("userToken", response.accessToken);
        this.setState({
          isSignedIn: true,
        });
        successfulSignIn = true;
      } else {
        throw Error("not found any current active account");
      }
    } catch (error) {
      sessionStorage.clear();
      window.location.replace("/");
    }
  };

  handletimeZoneSelect = (timezone) => {
    this.setState({
      timeZone: timezone?.value,
    });
  };

  closePopup = () => {
    this.setState({
      isPopupOpen: false,
    });
    const { navigate } = this.props;
    navigate("/Home");
  };

  fetchData = async (propertyname, searchString) => {
    var successfulSignIn = false;
    var accessToken = "";
    try {
      const { instance, accounts } = this.props.msalContext;
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: [
            "User.Read",
            "openid",
            "profile",
            "offline_access",
            "email",
            "User.ReadBasic.All",
          ],
          account: accounts ? accounts[0] : null,
        };
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        sessionStorage.setItem("userName", response.account.name);
        sessionStorage.setItem("Emailid", response.account.username);
        sessionStorage.setItem("accountId", response.account.localAccountId);
        accessToken = response.accessToken;
        sessionStorage.setItem("userToken", response.accessToken);
        this.setState({
          isSignedIn: true,
        });
        successfulSignIn = true;
      } else {
        throw Error("not found any current active account");
      }
    } catch (error) {
      console.log("Error acquiring token silently:", error);
      sessionStorage.clear();
      window.location.replace("/");
    }
    if (successfulSignIn) {
      const url = `https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(mail,'@microsoft.com') and (startsWith(displayName,'${searchString}') or startsWith(mail,'${searchString}'))`;
      const headers = {
        ConsistencyLevel: "eventual",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get(url, { headers });
        this.setState({
          [propertyname]: response.data?.value,
        });
      } catch (error) {
        this.setState({
          [propertyname]: [],
        });
      }
    }
  };

  handleDropdownChange = (name, option) => {
    this.setState({ [name]: option });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    if (
      name === "isCampaignOrProductLaunch" &&
      (value === "false" || value === false)
    ) {
      this.setState({ assignedPMM: [] });
    }
  };

  handleSearchInputChange = (userPropertyName, searchString) => {
    this.fetchData(userPropertyName, searchString);
  };

  handleFileChange = (attachmentName, event) => {
    const file = event.target.files[0];
    if (attachmentName === "authorOrPresenterHeadshotAttachment") {
      this.setState({
        authorOrPresenterHeadshotAttachment: file?.name ? file.name : "",
        authorOrPresenterHeadshotAttachmentFile: file,
      });
    } else {
      this.setState({
        additionalDocsOrGraphicsAttachment: file?.name ? file.name : "",
        additionalDocsOrGraphicsAttachmentFile: file,
      });
    }
  };

  handleDrop = (attachmentName, acceptedFiles) => {
    const file = acceptedFiles[0];
    if (attachmentName === "authorOrPresenterHeadshotAttachment") {
      this.setState({
        authorOrPresenterHeadshotAttachment: file?.name ? file.name : "",
        authorOrPresenterHeadshotAttachmentFile: file,
      });
    } else {
      this.setState({
        additionalDocsOrGraphicsAttachment: file?.name ? file.name : "",
        additionalDocsOrGraphicsAttachmentFile: file,
      });
    }
  };

  handleUserChange = (userPropertyName, option) => {
    if (option) {
      this.setState({
        [userPropertyName]: [
          {
            ...option,
            label: option.displayName,
          },
        ],
      });
    } else {
      this.setState({
        [userPropertyName]: [],
      });
    }
  };

  onEditorStateChange = (label, editorState) => {
    if (editorState.replace(/<[^>]+>/g, "")?.trim() === "") {
      editorState = "";
    }
    this.setState({
      [label]: editorState,
    });
  };

  Previous = () => {
    const { navigate } = this.props;
    navigate("/Home");
  };

  openPopup = () => {
    this.setState({
      isPopupOpen: true,
    });
  };

  closeViewModePopup = () => {
    this.setState({
      isViewMode: false,
    });
  };

  handleSubmit = async (event) => {
    if (this.state.requestProcessing === false) {
      this.setState({
        requestProcessing: true,
      });
      event.preventDefault();
      const errors = {};
      const timeZoneLabel =
        document.getElementById("timeZone")?.innerText || "";
      this.setState({
        timeZoneLabel: document.getElementById("timeZone")?.innerText || "",
      });
      if (
        this.state.contentAssetsType === null ||
        (typeof this.state.contentAssetsType === "string" &&
          this.state.contentAssetsType?.trim() === "")
      ) {
        errors.contentAssetsType = true;
      }
      if (
        this.state.title === null ||
        (typeof this.state.title === "string" &&
          this.state.title?.trim() === "")
      ) {
        errors.title = true;
      }
      if (
        this.state.contentOwnerName === null ||
        (typeof this.state.contentOwnerName === "string" &&
          this.state.contentOwnerName?.trim() === "") ||
        this.state.contentOwnerName?.length <= 0
      ) {
        errors.contentOwnerName = true;
      }
      if (
        this.state.descriptionSummary === null ||
        (typeof this.state.descriptionSummary === "string" &&
          this.state.descriptionSummary?.trim() === "")
      ) {
        errors.descriptionSummary = true;
      }
      if (
        this.state.businessUseCase === null ||
        (typeof this.state.businessUseCase === "string" &&
          this.state.businessUseCase?.trim() === "")
      ) {
        errors.businessUseCase = true;
      }
      if (
        this.state.focusedProductSearvice === null ||
        (typeof this.state.focusedProductSearvice === "string" &&
          this.state.focusedProductSearvice?.trim() === "")
      ) {
        errors.focusedProductSearvice = true;
      }
      if (
        this.state.desiredOutcome === null ||
        (typeof this.state.desiredOutcome === "string" &&
          this.state.desiredOutcome?.trim() === "")
      ) {
        errors.desiredOutcome = true;
      }
      if (
        this.state.isCampaignOrProductLaunch === null ||
        (typeof this.state.isCampaignOrProductLaunch === "string" &&
          this.state.isCampaignOrProductLaunch?.trim() === "")
      ) {
        errors.isCampaignOrProductLaunch = true;
      }
      if (
        (this.state.isCampaignOrProductLaunch === true ||
          this.state.isCampaignOrProductLaunch === "true") &&
        (this.state.assignedPMM === null ||
          (typeof this.state.assignedPMM === "string" &&
            this.state.assignedPMM?.trim() === "") ||
          this.state.assignedPMM?.length <= 0)
      ) {
        errors.assignedPMM = true;
      }
      if (
        this.state.presenter === null ||
        (typeof this.state.presenter === "string" &&
          this.state.presenter?.trim() === "") ||
        this.state.presenter?.length <= 0
      ) {
        errors.presenter = true;
      }
      if (
        this.state.prsenterLocation === null ||
        (typeof this.state.prsenterLocation === "string" &&
          this.state.prsenterLocation?.trim() === "")
      ) {
        errors.prsenterLocation = true;
      }
      if (
        this.state.timeZone === null ||
        (typeof this.state.timeZone === "string" &&
          (this.state.timeZone?.trim() === "" ||
            this.state.timeZone?.trim() === "undefined"))
      ) {
        errors.timeZone = true;
      }
      if (
        this.state.publicationDate === null ||
        (typeof this.state.publicationDate === "string" &&
          this.state.publicationDate?.trim() === "")
      ) {
        errors.publicationDate = true;
      }
      if (
        this.state.expirationDate === null ||
        (typeof this.state.expirationDate === "string" &&
          this.state.expirationDate?.trim() === "")
      ) {
        errors.expirationDate = true;
      }
      const startOfminPublicationDate = moment(this.state.minDate)
        .startOf("day")
        .format("YYYY-MM-DD");
      const endOfmaxExpiryDateError = moment(this.state.publicationDate)
        .add(1, "years")
        .startOf("day")
        .format("YYYY-MM-DD");
      if (
        (this.state.publicationDate !== null ||
          (typeof this.state.publicationDate === "string" &&
            this.state.publicationDate?.trim() !== "")) &&
        new Date(startOfminPublicationDate) >
          new Date(this.state.publicationDate)
      ) {
        errors.publicationDate = true;
        errors.minPublicationDateError = true;
      }

      if (
        (this.state.expirationDate !== null ||
          (typeof this.state.expirationDate === "string" &&
            this.state.expirationDate?.trim() !== "")) &&
        (new Date(endOfmaxExpiryDateError) <
          new Date(this.state.expirationDate) ||
          ((this.state.publicationDate !== null ||
            (typeof this.state.publicationDate === "string" &&
              this.state.publicationDate?.trim() !== "")) &&
            new Date(this.state.expirationDate) <=
              new Date(this.state.publicationDate)))
      ) {
        errors.expirationDate = true;
        errors.maxExpiryDateError = true;
      }
      if (Object.keys(errors).length === 0) {
        // All fields are valid, proceed to the next page
        // You can navigate or perform any necessary action here

        this.setState({ errors: {} });
        const formData = new FormData();
        // formData.append('engagement_type', 'PG Buddy Program');
        if (this.state.user_name) {
          formData.append("user_name", this.state.user_name);
        }
        if (this.state.user_email) {
          formData.append("user_email", this.state.user_email);
        }
        if (
          this.state.contentAssetsType ||
          this.state.contentAssetsType !== ""
        ) {
          formData.append(
            "contentAssetType",
            this.state.contentAssetsType?.value || ""
          );
        } else {
          formData.append("contentAssetType", "");
        }
        if (
          this.state.contentOwnerName &&
          this.state.contentOwnerName[0]?.value
        ) {
          formData.append(
            "contentOwnerName",
            this.state.contentOwnerName[0].displayName || ""
          );
          formData.append(
            "contentOwnerId",
            this.state.contentOwnerName[0].localId || ""
          );
          formData.append(
            "contentOwnerEmail",
            this.state.contentOwnerName[0].mail || ""
          );
        } else {
          formData.append("contentOwnerName", "");
          formData.append("contentOwnerId", "");
          formData.append("contentOwnerEmail", "");
        }
        if (this.state.assignedPMM && this.state.assignedPMM[0]?.value) {
          formData.append(
            "PPMAssignedName",
            this.state.assignedPMM[0]?.displayName || ""
          );
          formData.append(
            "PPMAssignedId",
            this.state.assignedPMM[0]?.localId || ""
          );
          formData.append(
            "PPMAssignedEmail",
            this.state.assignedPMM[0]?.mail || ""
          );
        } else {
          formData.append("PPMAssignedName", "");
          formData.append("PPMAssignedId", "");
          formData.append("PPMAssignedEmail", "");
        }
        if (this.state.presenter && this.state.presenter[0]?.value) {
          formData.append(
            "MicrosoftPresenterName",
            this.state.presenter[0]?.displayName || ""
          );
          formData.append(
            "MicrosoftPresenterID",
            this.state.presenter[0]?.localId || ""
          );
          formData.append(
            "MicrosoftPresenterEmail",
            this.state.presenter[0]?.mail || ""
          );
        } else {
          formData.append("MicrosoftPresenterName", "");
          formData.append("MicrosoftPresenterID", "");
          formData.append("MicrosoftPresenterEmail", "");
        }
        if (this.state.title || this.state.title !== "") {
          formData.append("title", this.state.title);
        } else {
          formData.append("title", "");
        }
        if (this.state.businessArea || this.state.businessArea !== "") {
          formData.append("businessArea", this.state.businessArea);
        } else {
          formData.append("businessArea", "");
        }
        if (
          this.state.descriptionSummary ||
          this.state.descriptionSummary !== ""
        ) {
          formData.append(
            "descriptionOrExecutiveSummary",
            this.state.descriptionSummary
          );
        } else {
          formData.append("descriptionOrExecutiveSummary", "");
        }
        if (
          this.state.focusedProductSearvice ||
          this.state.focusedProductSearvice !== ""
        ) {
          formData.append(
            "productOrServiceName",
            this.state.focusedProductSearvice
          );
        } else {
          formData.append("productOrServiceName", "");
        }
        if (this.state.businessUseCase || this.state.businessUseCase !== "") {
          formData.append("businessOrUseCase", this.state.businessUseCase);
        } else {
          formData.append("businessOrUseCase", "");
        }
        if (this.state.desiredOutcome || this.state.desiredOutcome !== "") {
          formData.append("desiredOutcome", this.state.desiredOutcome);
        } else {
          formData.append("desiredOutcome", "");
        }
        formData.append(
          "marketingCampaignOrUpcomingLaunch",
          this.state.isCampaignOrProductLaunch
        );
        if (
          this.state.nonMicrosoftPresenter ||
          this.state.nonMicrosoftPresenter !== ""
        ) {
          formData.append(
            "nameOfNonMicrosoftPresenter",
            this.state.nonMicrosoftPresenter
          );
        } else {
          formData.append("nameOfNonMicrosoftPresenter", "");
        }
        if (this.state.prsenterLocation || this.state.prsenterLocation !== "") {
          formData.append("presenterLocation", this.state.prsenterLocation);
        } else {
          formData.append("presenterLocation", "");
        }
        if (this.state.timeZone || this.state.timeZone !== "") {
          formData.append("PresenterTimeZone", this.state.timeZone);
          formData.append("PresenterTimeZoneLabel", timeZoneLabel);
        } else {
          formData.append("PresenterTimeZone", "");
          formData.append("PresenterTimeZoneLabel", "");
        }
        if (this.state.publicationDate || this.state.publicationDate !== "") {
          formData.append("publicationDate", this.state.publicationDate);
        } else {
          formData.append("publicationDate", "");
        }
        if (this.state.expirationDate || this.state.expirationDate !== "") {
          formData.append("ExpirationDate", this.state.expirationDate);
        } else {
          formData.append("ExpirationDate", "");
        }
        if (
          this.state.sessionLength ||
          this.state.sessionLength !== "" ||
          this.state.sessionLength !== null ||
          this.state.sessionLength !== "null"
        ) {
          formData.append("segmentLength", this.state.sessionLength);
        } else {
          formData.append("segmentLength", "");
        }
        if (this.state.metaDataKeyword || this.state.metaDataKeyword !== "") {
          formData.append("metadataKeywords", this.state.metaDataKeyword);
        } else {
          formData.append("metadataKeywords", "");
        }
        if (this.state.targetAudience || this.state.targetAudience !== "") {
          formData.append("typeOfAudience", this.state.targetAudience);
        } else {
          formData.append("typeOfAudience", "");
        }
        if (this.state.allContentLinks || this.state.allContentLinks !== "") {
          formData.append("resourceLinks", this.state.allContentLinks);
        } else {
          formData.append("resourceLinks", "");
        }
        if (this.state.additionalInfo || this.state.additionalInfo !== "") {
          formData.append("miscNotes", this.state.additionalInfo);
        } else {
          formData.append("miscNotes", "");
        }
        if (
          this.state.authorOrpresenterShortBio ||
          this.state.authorOrpresenterShortBio !== ""
        ) {
          formData.append(
            "authorOrpresenterShortBio",
            this.state.authorOrpresenterShortBio
          );
        } else {
          formData.append("authorOrpresenterShortBio", "");
        }
        if (
          this.state.authorOrpresenterSocialLinks ||
          this.state.authorOrpresenterSocialLinks !== ""
        ) {
          formData.append(
            "authorOrpresenterSocialLinks",
            this.state.authorOrpresenterSocialLinks
          );
        } else {
          formData.append("authorOrpresenterSocialLinks", "");
        }
        formData.append(
          "authorOrPresenterHeadshotAttachment",
          this.state.authorOrPresenterHeadshotAttachmentFile
        );
        formData.append(
          "additionalDocsOrGraphicsAttachment",
          this.state.additionalDocsOrGraphicsAttachmentFile
        );
        try {
          this.setState({
            showLoader: true,
          });
          var response;
          const url =
            `${config.apiBaseUrl}/request/technicalRequest/` +
            sessionStorage.getItem("accountId");
          if (this.state.openmode === "edit") {
            if (this.state.requestId) {
              formData.append("request_id", this.state.requestId);
            }
            if (this.state.requestWorkitemId) {
              formData.append("workitemid", this.state.requestWorkitemId);
            }
            response = await axios.put(url, formData, {
              headers: {
                "Content-Type": undefined,
              },
            });
          } else {
            response = await axios.post(url, formData, {
              headers: {
                "Content-Type": undefined,
              },
            });
          }
          this.setState({
            requestId: response.data.data?.fields["Custom.RequestID"],
            showLoader: false,
          });
          this.openPopup();
        } catch (error) {
          this.setState({
            showLoader: false,
          });
          toast.error("Failed to submit request!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else {
        this.setState({ errors });
        toast.warn("Please fill the required fields highlighted into red!", {
          position: toast.POSITION.TOP_CENTER,
          toastId: 2,
        });
      }
      this.setState({
        requestProcessing: false,
      });
    }
  };

  render() {
    const { errors } = this.state;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minHeight: "45px",
        height: "45px",
      }),

      valueContainer: (provided, state) => ({
        ...provided,
        height: "45px",
        padding: "0 6px",
      }),

      input: (provided, state) => ({
        ...provided,
        margin: "0px",
      }),
      indicatorSeparator: (state) => ({
        display: "none",
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "45px",
      }),
    };
    return (
      <>
        {" "}
        {this.state.isSignedIn && (
          <div className="technicalContentRequestContainear">
            <NavBar></NavBar>
            <div className="techincalContentTitle">
              Technical Content Request Details
            </div>
            <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
              <Divider></Divider>
            </div>
            <form className="form" style={{ width: "100%" }}>
              <fieldset
                disabled={this.state.openmode === "view" ? "disabled" : ""}
              >
                <Grid
                  container
                  spacing={0}
                  style={{
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    paddingTop: "2%",
                  }}
                  className="techincalContentBody"
                >
                  <div
                    className="skip-note"
                    style={{ paddingBottom: "30px", marginTop: "-10px" }}
                  >
                    <span className="bolder">Note:</span> Please feel free to
                    skip fields that do not apply to your request.
                  </div>
                  <Grid item xs={4} sm={4}>
                    <div className="input-field">
                      <div className="label-container">
                        <label
                          htmlFor="contentAssetsType"
                          className={`input-label ${
                            errors.contentAssetsType ? "required-label" : ""
                          }`}
                        >
                          Content Assets Type *
                        </label>
                      </div>
                      <Select
                        id="contentAssetsType"
                        name="contentAssetsType"
                        styles={customStyles}
                        options={contentAssetsTypeOptions.map((option) => ({
                          value: option?.value,
                          label: option.label,
                        }))}
                        value={this.state.contentAssetsType}
                        onChange={(selectedOption) =>
                          this.handleDropdownChange(
                            "contentAssetsType",
                            selectedOption
                          )
                        }
                        error={!!errors.contentAssetsType}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field-with-help-text">
                      <div className="label-container">
                        <label
                          htmlFor="title"
                          className={`input-label ${
                            errors.title ? "required-label" : ""
                          }`}
                        >
                          Title *
                        </label>
                      </div>
                      <TextField
                        id="title"
                        name="title"
                        placeholder="Enter title"
                        value={this.state.title}
                        onChange={this.handleChange}
                        error={!!errors.title}
                        inputProps={{ maxLength: 200 }}
                        helperText={
                          this.state.title !== null &&
                          this.state.title?.length > 0
                            ? `${
                                200 - this.state.title?.length
                              } characters remaining`
                            : "200 characters remaining"
                        }
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field-with-help-text">
                      <div className="label-container">
                        <label htmlFor="businessArea" className={`input-label`}>
                          Business Area
                        </label>
                      </div>
                      <TextField
                        id="businessArea"
                        name="businessArea"
                        placeholder="Enter Business Area"
                        value={this.state.businessArea}
                        onChange={this.handleChange}
                        error={!!errors.title}
                        inputProps={{ maxLength: 200 }}
                        helperText={
                          this.state.businessArea !== null &&
                          this.state.businessArea?.length > 0
                            ? `${
                                200 - this.state.businessArea?.length
                              } characters remaining`
                            : "200 characters remaining"
                        }
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field-with-help-text">
                      <div className="label-container">
                        <label
                          htmlFor="focusedProductSearvice"
                          className={`input-label ${
                            errors.focusedProductSearvice
                              ? "required-label"
                              : ""
                          }`}
                        >
                          Product, service or feature name *
                        </label>
                      </div>
                      <TextField
                        id="focusedProductSearvice"
                        name="focusedProductSearvice"
                        placeholder="Enter Focused product, service or feature name"
                        value={this.state.focusedProductSearvice}
                        onChange={this.handleChange}
                        error={!!errors.focusedProductSearvice}
                        inputProps={{ maxLength: 200 }}
                        helperText={
                          this.state.focusedProductSearvice !== null &&
                          this.state.focusedProductSearvice?.length > 0
                            ? `${
                                200 - this.state.focusedProductSearvice?.length
                              } characters remaining`
                            : "200 characters remaining"
                        }
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field">
                      <div className="label-container">
                        <label
                          htmlFor="contentOwnerName"
                          className={`input-label ${
                            errors.contentOwnerName ? "required-label" : ""
                          }`}
                        >
                          Content owner name *
                        </label>
                      </div>
                      <Grid item xs={12}>
                        <FormControl>
                          <Select
                            value={this.state.contentOwnerName}
                            onChange={(option) => {
                              this.handleUserChange("contentOwnerName", option);
                            }}
                            onInputChange={(searchString) => {
                              this.handleSearchInputChange(
                                "contentOwnerNameOptions",
                                searchString
                              );
                            }}
                            styles={customStyles}
                            isClearable
                            options={this.state.contentOwnerNameOptions.map(
                              (option) => ({
                                value: option.displayName + " | " + option.mail,
                                displayName: option.displayName || "",
                                mail: option.mail || "",
                                localId: option.localId || "",
                                label: (
                                  <>
                                    <Divider
                                      style={{
                                        width: "95%",
                                        color: "#707070",
                                        marginLeft: "10",
                                      }}
                                    />
                                    <div style={{ marginTop: "0.2rem" }}>
                                      <div>
                                        <b>
                                          <span style={{ fontSize: 16 }}>
                                            {option.displayName}
                                          </span>
                                        </b>
                                        <br />
                                        <span
                                          style={{
                                            fontSize: 11,
                                            color: "grey",
                                            marginTop: "5rem",
                                          }}
                                        >
                                          {option.mail}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ),
                              })
                            )}
                            placeholder="Search Content Owner Name"
                          />
                        </FormControl>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field">
                      <Grid item xs={12} sm={12}>
                        <div className="label-container">
                          <label
                            htmlFor="descriptionSummary"
                            className={`input-label ${
                              errors.descriptionSummary ? "required-label" : ""
                            }`}
                          >
                            Description, abstract or executive summary *
                          </label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div className="richtext-containear">
                          <ReactQuill
                            id="descriptionSummary"
                            theme="snow"
                            placeholder="Enter Description, abstract or executive summary"
                            value={this.state.descriptionSummary}
                            onChange={(editorState) => {
                              this.onEditorStateChange(
                                "descriptionSummary",
                                editorState
                              );
                            }}
                            disabled={this.state.openmode === "view"}
                            className="react-quill"
                            readOnly={
                              this.state.openmode === "view" ? true : false
                            }
                          />
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field">
                      <Grid item xs={12} sm={12}>
                        <div className="label-container">
                          <label
                            htmlFor="businessUseCase"
                            className={`input-label ${
                              errors.businessUseCase ? "required-label" : ""
                            }`}
                          >
                            What is the problem that you are trying to solve?
                            What is your business or use case? *
                          </label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div className="richtext-containear">
                          <ReactQuill
                            id="businessUseCase"
                            theme="snow"
                            placeholder="Enter business or use case"
                            value={this.state.businessUseCase}
                            onChange={(editorState) => {
                              this.onEditorStateChange(
                                "businessUseCase",
                                editorState
                              );
                            }}
                            disabled={this.state.openmode === "view"}
                            className="react-quill"
                            readOnly={
                              this.state.openmode === "view" ? true : false
                            }
                          />
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field">
                      <Grid item xs={12} sm={12}>
                        <div className="label-container">
                          <label
                            htmlFor="desiredOutcome"
                            className={`input-label ${
                              errors.desiredOutcome ? "required-label" : ""
                            }`}
                          >
                            What is your desired outcome? How did you solve the
                            problem using AI technology? *
                          </label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div className="richtext-containear">
                          <ReactQuill
                            id="desiredOutcome"
                            theme="snow"
                            placeholder="Enter desired outcome"
                            value={this.state.desiredOutcome}
                            onChange={(editorState) => {
                              this.onEditorStateChange(
                                "desiredOutcome",
                                editorState
                              );
                            }}
                            disabled={this.state.openmode === "view"}
                            className="react-quill"
                            readOnly={
                              this.state.openmode === "view" ? true : false
                            }
                          />
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field">
                      <div className="radio-button-label-containear">
                        <label
                          htmlFor="isCampaignOrProductLaunch"
                          className={`input-label ${
                            errors.isCampaignOrProductLaunch
                              ? "required-label"
                              : ""
                          }`}
                        >
                          Is this request part of a marketing campaign or
                          upcoming product launch? *
                        </label>
                      </div>
                      <RadioGroup
                        id="isCampaignOrProductLaunch"
                        name="isCampaignOrProductLaunch"
                        value={this.state.isCampaignOrProductLaunch}
                        onChange={this.handleChange}
                        error={!!errors.isCampaignOrProductLaunch}
                      >
                        <div>
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                            className="radio-button-label"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </div>
                      </RadioGroup>
                      {errors.isCampaignOrProductLaunch && (
                        <span className="error-message">
                          {errors.isCampaignOrProductLaunch}
                        </span>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={2}></Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field">
                      <div className="label-container">
                        <label
                          htmlFor="assignedPMM"
                          className={`input-label ${
                            errors.assignedPMM ? "required-label" : ""
                          }`}
                        >
                          {`Name of PMM assigned to this product or service${
                            this.state.isCampaignOrProductLaunch === true ||
                            this.state.isCampaignOrProductLaunch === "true"
                              ? "*"
                              : ""
                          }`}
                        </label>
                      </div>
                      <Grid item xs={12}>
                        <FormControl>
                          <Select
                            value={this.state.assignedPMM}
                            onChange={(option) => {
                              this.handleUserChange("assignedPMM", option);
                            }}
                            onInputChange={(searchString) => {
                              this.handleSearchInputChange(
                                "assignedPMMOptions",
                                searchString
                              );
                            }}
                            styles={customStyles}
                            isClearable
                            isDisabled={
                              this.state.isCampaignOrProductLaunch === true ||
                              this.state.isCampaignOrProductLaunch === "true"
                                ? false
                                : true
                            }
                            options={this.state.assignedPMMOptions.map(
                              (option) => ({
                                value: option.displayName + " | " + option.mail,
                                displayName: option.displayName || "",
                                mail: option.mail || "",
                                localId: option.localId || "",
                                label: (
                                  <>
                                    <Divider
                                      style={{
                                        width: "95%",
                                        color: "#707070",
                                        marginLeft: "10",
                                      }}
                                    />
                                    <div style={{ marginTop: "0.2rem" }}>
                                      <div>
                                        <b>
                                          <span style={{ fontSize: 16 }}>
                                            {option.displayName}
                                          </span>
                                        </b>
                                        <br />
                                        <span
                                          style={{
                                            fontSize: 11,
                                            color: "grey",
                                            marginTop: "5rem",
                                          }}
                                        >
                                          {option.mail}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ),
                              })
                            )}
                            placeholder="Search assigned PMM"
                          />
                        </FormControl>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field">
                      <div className="label-container">
                        <label
                          htmlFor="presenter"
                          className={`input-label ${
                            errors.presenter ? "required-label" : ""
                          }`}
                        >
                          Name of Microsoft Author/presenter(s) *
                        </label>
                      </div>
                      <Grid item xs={12}>
                        <FormControl>
                          <Select
                            value={this.state.presenter}
                            onChange={(option) => {
                              this.handleUserChange("presenter", option);
                            }}
                            onInputChange={(searchString) => {
                              this.handleSearchInputChange(
                                "presenterOptions",
                                searchString
                              );
                            }}
                            styles={customStyles}
                            isClearable
                            options={this.state.presenterOptions.map(
                              (option) => ({
                                value: option.displayName + " | " + option.mail,
                                displayName: option.displayName || "",
                                mail: option.mail || "",
                                localId: option.localId || "",
                                label: (
                                  <>
                                    <Divider
                                      style={{
                                        width: "95%",
                                        color: "#707070",
                                        marginLeft: "10",
                                      }}
                                    />
                                    <div style={{ marginTop: "0.2rem" }}>
                                      <div>
                                        <b>
                                          <span style={{ fontSize: 16 }}>
                                            {option.displayName}
                                          </span>
                                        </b>
                                        <br />
                                        <span
                                          style={{
                                            fontSize: 11,
                                            color: "grey",
                                            marginTop: "5rem",
                                          }}
                                        >
                                          {option.mail}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ),
                              })
                            )}
                            placeholder="Search Microsoft presenter"
                          />
                        </FormControl>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={2}></Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field-with-help-text">
                      <div className="label-container">
                        <label
                          htmlFor="nonMicrosoftPresenter"
                          className={`input-label`}
                        >
                          Name of non-Microsoft Author/presenter(s)
                        </label>
                      </div>
                      <TextField
                        id="nonMicrosoftPresenter"
                        name="nonMicrosoftPresenter"
                        placeholder="Enter non-Microsoft presenter(s) name"
                        value={this.state.nonMicrosoftPresenter}
                        onChange={this.handleChange}
                        error={!!errors.nonMicrosoftPresenter}
                        inputProps={{ maxLength: 200 }}
                        helperText={
                          this.state.nonMicrosoftPresenter !== null &&
                          this.state.nonMicrosoftPresenter?.length > 0
                            ? `${
                                200 - this.state.nonMicrosoftPresenter?.length
                              } characters remaining`
                            : "200 characters remaining"
                        }
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field">
                      <Grid item xs={12} sm={12}>
                        <div className="label-container">
                          <label
                            htmlFor="authorOrpresenterShortBio"
                            className={`input-label`}
                          >
                            Author/presenter(s) short bio
                          </label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="richtext-containear">
                          <ReactQuill
                            id="authorOrpresenterShortBio"
                            theme="snow"
                            placeholder="Enter Author/presenter(s) short bio"
                            value={this.state.authorOrpresenterShortBio}
                            onChange={(editorState) => {
                              this.onEditorStateChange(
                                "authorOrpresenterShortBio",
                                editorState
                              );
                            }}
                            disabled={this.state.openmode === "view"}
                            className="react-quill"
                            readOnly={
                              this.state.openmode === "view" ? true : false
                            }
                          />
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={2}></Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field">
                      <Grid item xs={11} sm={11}>
                        <div className="label-container">
                          <label
                            htmlFor="authorOrpresenterSocialLinks"
                            className={`input-label`}
                          >
                            Author/presenter(s) social links
                          </label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="richtext-containear">
                          <ReactQuill
                            id="authorOrpresenterSocialLinks"
                            theme="snow"
                            placeholder="Enter Author/presenter(s) social links"
                            value={this.state.authorOrpresenterSocialLinks}
                            onChange={(editorState) => {
                              this.onEditorStateChange(
                                "authorOrpresenterSocialLinks",
                                editorState
                              );
                            }}
                            disabled={this.state.openmode === "view"}
                            className="react-quill"
                            readOnly={
                              this.state.openmode === "view" ? true : false
                            }
                          />
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ paddingRight: "10px" }}>
                    <div
                      className={`input-field ${
                        this.state.openmode === "view"
                          ? "poniter-event-none"
                          : ""
                      }`}
                    >
                      <div className="label-container">
                        <label
                          htmlFor="authorOrPresenterHeadshotAttachment"
                          className="input-label"
                        >
                          Author/presenter(s) headshot
                        </label>
                      </div>
                      <div className="drag-drop-file-upload">
                        <FileDropzone
                          onDrop={
                            this.state.openmode !== "view"
                              ? (files) => {
                                  this.handleDrop(
                                    "authorOrPresenterHeadshotAttachment",
                                    files
                                  );
                                }
                              : undefined
                          }
                          className="pgBuddyDropZone"
                        >
                          <input
                            type="text"
                            value={
                              this.state.authorOrPresenterHeadshotAttachment
                            }
                            placeholder="Drag and Drop Attachment"
                            readOnly
                            className="file-name"
                          />
                        </FileDropzone>
                        <label
                          className={`upload-btn ${
                            this.state.openmode === "view"
                              ? "disable-upload"
                              : ""
                          }`}
                        >
                          Upload
                          <input
                            type="file"
                            onChange={(event) => {
                              this.handleFileChange(
                                "authorOrPresenterHeadshotAttachment",
                                event
                              );
                            }}
                            style={{ display: "none" }}
                          />
                        </label>
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={6} sm={6}></Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field-with-help-text">
                      <div className="label-container">
                        <label
                          htmlFor="prsenterLocation"
                          className={`input-label ${
                            errors.prsenterLocation ? "required-label" : ""
                          }`}
                        >
                          Where is the presenter(s) located? *
                        </label>
                      </div>
                      <TextField
                        id="prsenterLocation"
                        name="prsenterLocation"
                        placeholder="Enter presenter(s) location"
                        value={this.state.prsenterLocation}
                        onChange={this.handleChange}
                        error={!!errors.prsenterLocation}
                        inputProps={{ maxLength: 200 }}
                        helperText={
                          this.state.prsenterLocation !== null &&
                          this.state.prsenterLocation?.length > 0
                            ? `${
                                200 - this.state.prsenterLocation?.length
                              } characters remaining`
                            : "200 characters remaining"
                        }
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={2}></Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field">
                      <div className="label-container-with-info-icon">
                        <label
                          htmlFor="timeZone"
                          className={`input-label ${
                            errors.timeZone ? "required-label" : ""
                          }`}
                        >
                          What is the presenter(s) time zone? *
                        </label>
                      </div>
                      <TimezoneSelect
                        id="timeZone"
                        name="timeZone"
                        placeholder="Select timezone"
                        styles={customStyles}
                        value={this.state.timeZone}
                        onChange={this.handletimeZoneSelect}
                        error={!!errors.timeZone}
                        helperText={errors.timeZone}
                        //   isDisabled={this.state.requestedDate === "Flexible"} // Disable if requestedDate is "Flexible"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field">
                      <div className="label-container">
                        <label
                          htmlFor="publicationDate"
                          className={`input-label ${
                            errors.publicationDate ? "required-label" : ""
                          }`}
                        >
                          What is your desired publication date? *
                        </label>
                      </div>
                      <TextField
                        id="publicationDate"
                        name="publicationDate"
                        type="date"
                        value={this.state.publicationDate}
                        inputProps={{
                          shrink: true,
                          min: this.state.minDate,
                        }}
                        onChange={this.handleChange}
                        error={!!errors.publicationDate}
                        helperText={errors.publicationDate}
                      />
                    </div>
                    <div className="date-note">
                      Note: 1 week lead time required.
                      {errors.minPublicationDateError && (
                        <div className="error-note-speaker">
                          Please allow a lead time of 1 week. If this is an
                          urgent request, please reach out to Engage AI{" "}
                          <a
                            href="mailto:engageai@microsoft.com"
                            title="mailto:engageai@microsoft.com"
                          >
                            engageai@microsoft.com.
                          </a>
                        </div>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={2} sm={2}></Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field">
                      <div className="label-container">
                        <label
                          htmlFor="expirationDate"
                          className={`input-label ${
                            errors.expirationDate ? "required-label" : ""
                          }`}
                        >
                          Expected expiration date? (cannot exceed 1 year) *
                        </label>
                      </div>
                      <TextField
                        id="expirationDate"
                        name="expirationDate"
                        type="date"
                        value={this.state.expirationDate}
                        onChange={this.handleChange}
                        error={!!errors.expirationDate}
                        helperText={errors.expirationDate}
                        // inputProps={{
                        //     shrink: true,
                        //     max: this.state.maxDate,
                        // }}
                      />
                    </div>
                    {errors.maxExpiryDateError && (
                      <div className="error-note-speaker">
                        Expected expiration date should be greter than and
                        within 1 year of desired publication date. *
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field">
                      <div className="label-container">
                        <label
                          htmlFor="sessionLength"
                          className={`input-label`}
                        >
                          Length of segment/session (in minutes)
                        </label>
                      </div>
                      <TextField
                        id="sessionLength"
                        name="sessionLength"
                        type="number"
                        placeholder="Enter session length (in mins)"
                        value={this.state.sessionLength}
                        onChange={this.handleChange}
                        onBlur={() => {
                          if (
                            this.state.sessionLength &&
                            this.state.sessionLength < 0
                          ) {
                            this.setState({
                              sessionLength: 0,
                            });
                          } else {
                            const enteredValue = parseFloat(
                              this.state.sessionLength
                            );
                            if (
                              this.state.sessionLength &&
                              !Number.isInteger(enteredValue)
                            ) {
                              this.setState({
                                sessionLength: Math.floor(enteredValue),
                              });
                            }
                          }
                        }}
                        error={!!errors.sessionLength}
                        inputProps={{ min: 0 }}
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={2}></Grid>
                  <Grid item xs={5} sm={5}>
                    <div className="input-field-with-help-text">
                      <div className="label-container">
                        <label
                          htmlFor="metaDataKeyword"
                          className={`input-label`}
                        >
                          Provide metadata: 5-6 terms, phrases or keywords
                          relevant to your content
                        </label>
                      </div>
                      <TextField
                        id="metaDataKeyword"
                        name="metaDataKeyword"
                        placeholder="Enter metadata keywords"
                        value={this.state.metaDataKeyword}
                        onChange={this.handleChange}
                        error={!!errors.metaDataKeyword}
                        inputProps={{ maxLength: 200 }}
                        helperText={
                          this.state.metaDataKeyword !== null &&
                          this.state.metaDataKeyword?.length > 0
                            ? `${
                                200 - this.state.metaDataKeyword?.length
                              } characters remaining`
                            : "200 characters remaining"
                        }
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field-with-help-text">
                      <div className="label-container">
                        <label
                          htmlFor="targetAudience"
                          className={`input-label`}
                        >
                          Who is the target audience(s)?
                        </label>
                      </div>
                      <TextField
                        id="targetAudience"
                        name="targetAudience"
                        placeholder="Enter type of audience are you anticipating to reach"
                        value={this.state.targetAudience}
                        onChange={this.handleChange}
                        error={!!errors.targetAudience}
                        inputProps={{ maxLength: 200 }}
                        helperText={
                          this.state.targetAudience !== null &&
                          this.state.targetAudience?.length > 0
                            ? `${
                                200 - this.state.targetAudience?.length
                              } characters remaining`
                            : "200 characters remaining"
                        }
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field">
                      <Grid item xs={12} sm={8}>
                        <div className="label-container">
                          <label
                            htmlFor="allContentLinks"
                            className={`input-label`}
                          >
                            Provide 2-3 resource links - Where should viewers go
                            to learn more? Include links to Docs pages / Learn
                            modules / code samples / etc.
                          </label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div className="richtext-containear">
                          <ReactQuill
                            id="allContentLinks"
                            theme="snow"
                            placeholder="Enter resource links"
                            value={this.state.allContentLinks}
                            onChange={(editorState) => {
                              this.onEditorStateChange(
                                "allContentLinks",
                                editorState
                              );
                            }}
                            disabled={this.state.openmode === "view"}
                            className="react-quill"
                            readOnly={
                              this.state.openmode === "view" ? true : false
                            }
                          />
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="input-field">
                      <Grid item xs={12} sm={8}>
                        <div className="label-container">
                          <label
                            htmlFor="additionalInfo"
                            className={`input-label`}
                          >
                            Is there anything else you would like us to know
                            about this request?
                          </label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div className="richtext-containear">
                          <ReactQuill
                            id="additionalInfo"
                            theme="snow"
                            placeholder="Enter additional information you would like us to know about this request."
                            value={this.state.additionalInfo}
                            onChange={(editorState) => {
                              this.onEditorStateChange(
                                "additionalInfo",
                                editorState
                              );
                            }}
                            disabled={this.state.openmode === "view"}
                            className="react-quill"
                            readOnly={
                              this.state.openmode === "view" ? true : false
                            }
                          />
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ paddingLeft: "10px" }}>
                    <div
                      className={`input-field ${
                        this.state.openmode === "view"
                          ? "poniter-event-none"
                          : ""
                      }`}
                    >
                      <div className="label-container">
                        <label
                          htmlFor="additionalDocsOrGraphicsAttachment"
                          className="input-label"
                        >
                          Upload additional documentation/graphics
                        </label>
                      </div>
                      <div className="drag-drop-file-upload">
                        <FileDropzone
                          onDrop={
                            this.state.openmode !== "view"
                              ? (files) => {
                                  this.handleDrop(
                                    "additionalDocsOrGraphicsAttachment",
                                    files
                                  );
                                }
                              : undefined
                          }
                          className="pgBuddyDropZone"
                        >
                          <input
                            type="text"
                            value={
                              this.state.additionalDocsOrGraphicsAttachment
                            }
                            placeholder="Drag and Drop Attachment"
                            readOnly
                            className="file-name"
                          />
                        </FileDropzone>
                        <label
                          className={`upload-btn ${
                            this.state.openmode === "view"
                              ? "disable-upload"
                              : ""
                          }`}
                        >
                          Upload
                          <input
                            type="file"
                            onChange={(event) => {
                              this.handleFileChange(
                                "additionalDocsOrGraphicsAttachment",
                                event
                              );
                            }}
                            style={{ display: "none" }}
                          />
                        </label>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </fieldset>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12}>
                  <div className="pgBuddySubmit-buttons-container">
                    <Button
                      variant="outlined"
                      id="previous"
                      onClick={this.Previous}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      id="submit"
                      color="primary"
                      onClick={this.handleSubmit}
                      disabled={
                        this.state.openmode === "view" ? "disabled" : ""
                      }
                    >
                      {this.state.openmode === "edit" ? "update" : "Submit"}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
            {this.state.isPopupOpen && (
              <div className="popup-overlay">
                <div className="popup">
                  <div className="popup-content">
                    <div className="popup-image">
                      <img src={successImg} alt="Popup" />
                    </div>
                    {this.state.openmode === "" && (
                      <div className="popup-text">
                        <p>
                          This request was successfully submitted! Your Engage
                          AI Request Id is:
                          <span className="request-id">
                            {` ${this.state.requestId}.`}{" "}
                          </span>
                        </p>
                      </div>
                    )}
                    {this.state.openmode === "edit" && (
                      <div className="popup-text">
                        <p>
                          Your request{" "}
                          <span className="request-id">{` ${this.state.requestId}`}</span>{" "}
                          was successfully updated.
                        </p>
                      </div>
                    )}
                    <br />
                    <button className="popupSubmit" onClick={this.closePopup}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}
            {this.state.isViewMode && (
              <div className="ebc-popup-overlay">
                <div className="ebc-popup">
                  <div className="ebc-popup-content">
                    <p className="custom-line-height">
                      Your request has moved past 'content requested' status.
                      Hence, it can only be opened in READONLY mode. If you want
                      to make changes to this request, please reach out to{" "}
                      <a
                        href="mailto:engageai@microsoft.com"
                        title="mailto:engageai@microsoft.com"
                      >
                        engageai@microsoft.com
                      </a>
                      .
                    </p>
                    <br />
                    <button
                      className="popupSubmit"
                      onClick={this.closeViewModePopup}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}
            <ToastContainer />
          </div>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.showLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

export default compose(withRouter, withMsal)(TechnicalContentForm);
